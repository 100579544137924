import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { bage } from "../Utils/Images";
import { MdOutlineLocationOn, LuPhoneCall } from "../Utils/Icons";
import styled from "styled-components";
import Allied from "../components/Allied";
import HomeBanner from "../components/HomeBanner";
import { FiMail } from "react-icons/fi";
import { useAPI } from "../context/getContext";
import Navbar from "../components/Navbar";

const Contact = () => {
  const { contactData } = useAPI();
  const str = contactData.email;
  const phoneStr = contactData.phone;

  const array = str.split(",", 2);
  const arrayPhone = phoneStr.split(",", 2);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      {" "}
      <Helmet>
        <meta charSet="utf-8" />
        <title>RTH Redevelopment - Contact Us</title>
        <link rel="icon" id="faviconIcon" href={contactData.favicon} />
      </Helmet>
      <Navbar
        link1="link link--metis"
        link2="link link--metis"
        link3="link link--metis"
        link4="link link--metis"
        link5="link link--metis"
        link6="link"
      />
      <HomeBanner />
      <ContactMain>
        <div className="grid">
          <div className="card">
            <div className="label">
              <img src={bage} alt="" />
              <h4>CONTACT US WITH EASE</h4>
            </div>
            <h3>Get in Touch</h3>
            {/* <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. ut
              enim ad minim veniam.
            </p> */}
          </div>
          <div className="card flex-center">
            <div className="gridContact">
              <div className="cardContact">
                <div className="title-icon">
                  <MdOutlineLocationOn className="icon" />
                  <h4>Pune Office</h4>
                </div>
                <p>{contactData.address}</p>
              </div>
              <div className="cardContact">
                <div className="title-icon">
                  <FiMail className="icon" />
                  <h4>Mail</h4>
                </div>
                <ul>
                  {/* {contactData.email} */}
                  {/* {contactData.phone} */}
                  {array.map((item) => {
                    return (
                      <li>
                        <a href={`mailto:${item}`}>{item}</a>
                      </li>
                    );
                  })}
                  {/* <li>
                    <a href="mailto:manish.architect77@gmail.com">
                      manish.architect77@gmail.com
                    </a>
                  </li>
                  <li>
                    <a href="mailto:enquiry@rthredevelopment.com">
                      enquiry@rthredevelopment.com
                    </a>
                  </li> */}
                </ul>
              </div>
              <div className="cardContact">
                <div className="title-icon">
                  <LuPhoneCall className="icon" />
                  <h4>Contact Us</h4>
                </div>
                <ul>
                  {arrayPhone.map((item) => {
                    return (
                      <li>
                        <a href={`tel:${item}`}>{item}</a>
                      </li>
                    );
                  })}
                  {/* <li>
                    <a href="tel:9834222749">+919834222749</a>
                  </li>
                  <li>
                    <a href="tel:9881728458">+919881728458</a>
                  </li> */}
                </ul>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="border"></div> */}
      </ContactMain>
      <Allied />
    </div>
  );
};

export default Contact;

const ContactMain = styled.div`
  padding-top: 30px;
  padding-bottom: 0px;

  .border {
    background-color: #e2e9ff;
    height: 2px;
    width: 98%;
    margin: 2rem 10px;
  }
  .grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 0rem;
    padding: 0px 15px;

    .card {
      h3 {
        font-size: 45px;
        color: #0e1e36;
        margin-bottom: 15px;
      }
      p {
        width: 100%;
      }
      .gridContact {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 3rem;
        grid-row-gap: 3rem;

        .cardContact {
          width: 250px;
          .title-icon {
            display: flex;
            align-items: center;
            justify-content: flex-start;

            h4 {
              margin-left: 8px;
              font-size: 22px;
              font-weight: 600;
              color: #0e1e36;
            }
            .icon {
              font-size: 30px;
              color: var(--primary-color);
            }
          }
          p {
            margin-top: 1rem;
            font-size: 16px;
          }
          ul {
            margin-top: 1rem;
            li {
              margin-bottom: 3px;
              width: 80%;

              a {
                color: #000;
              }
            }
          }
        }
      }
    }

    @media only screen and (max-width: 991px) {
      grid-template-columns: 1fr;

      .card {
        h3 {
          font-size: 45px;
          color: #0e1e36;
          margin-bottom: 15px;
        }
        p {
          width: 100%;
        }
        .gridContact {
          grid-template-columns: 1fr;
        }
      }
    }
  }
`;
