import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { TermsMain } from "../Utils/CompoStyles";
import BannerCompo from "../components/BannerCompo";
import { useAPI } from "../context/getContext";
import Navbar from "../components/Navbar";

const Terms = () => {
  const { termsData, contactData } = useAPI();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>RTH Redevelopment - Terms and Condition</title>
        <link rel="icon" id="faviconIcon" href={contactData.favicon} />
      </Helmet>
      <Navbar
        link1="link link--metis"
        link2="link link--metis"
        link3="link link--metis"
        link4="link link--metis"
        link5="link link--metis"
        link6="link link--metis"
      />
      <BannerCompo
        title="Terms"
        titleName={termsData.title}
        headline="Terms and"
        subhead="Condition"
        img="https://images.unsplash.com/photo-1582883545851-725a3b9502ce?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=870&q=80"
      />
      <TermsMain>
        <div
          dangerouslySetInnerHTML={{
            __html: termsData.description,
          }}
        />
        {/* <h5>
          Lorem ipsum dolor, sit amet consectetur adipisicing elit. Aliquam,
          incidunt consequatur iste atque et, saepe molestiae quis tempora
          dignissimos facere, eaque nihil architecto! Tempore ea porro
          consequatur necessitatibus sapiente velit dolore! Vitae consequatur
          eligendi, aperiam accusantium.
        </h5>
        <ul>
          <li>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloremque
            soluta
          </li>
          <li>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloremque
            soluta est ducimus, aliquam dolorum ipsum fugit
          </li>
          <li>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloremque
            soluta est ducimus, aliquam dolorum ipsum fugit dolor iusto fugiat
            eos minus
          </li>
          <li>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloremque
            soluta est ducimus, aliquam dolorum ipsum fugit dolor iusto fugiat
            eos minus nam vel quam pariatur, quibusdam cumque nisi possimus?
            Libero.
          </li>
        </ul>
        <h3>
          "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corrupti,
          deleniti unde et nulla perspiciatis placeat eos maiores? Repudiandae a
          beatae quasi eveniet odit tempora aperiam libero fuga consectetur
          quas"
        </h3> */}
      </TermsMain>
    </div>
  );
};

export default Terms;
