import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { MdKeyboardDoubleArrowRight } from "../Utils/Icons";
import { pattern1 } from "../Utils/Images";
import { useAPI } from "../context/getContext";

const HomeBanner = () => {
  const { contactData } = useAPI();
  return (
    <BannerMain>
      <div className="card">
        <div className="overlay"></div>
        <div className="data">
          <h6 className="sub_title">
            Welcome To <span>RTH Redevelopment</span> Solutions <br /> One Stop
            Solution For Your Dream Redevelopment
          </h6>
          {/* <h4>One Stop Solution For Your </h4> */}
          {/* <h5>Dream Redevelopment</h5> */}
          <h4>From The Thought Of Redevelopment</h4>
          <h5>
            To Selection Of Developer <br /> In Just 60 Days
          </h5>
          <p>
            We Provide <strong>R</strong>apid <strong>T</strong>ransparent{" "}
            <strong>H</strong>aasle-free Solution for your Redevelopment
          </p>
          <div className="btnContainer">
            <Link
              to="/enquiry-for-consultancy"
              className="theme_btn btn-primary flex-center"
            >
              Enquiry for Redevelopment
              <MdKeyboardDoubleArrowRight className="iconRight" />
            </Link>
          </div>
        </div>
        <img src={pattern1} alt="" className="pattern" />
      </div>
      <div className="div_img">
        <img src={contactData.slider_img} alt="" className="hero_img" />
      </div>
    </BannerMain>
  );
};

export default HomeBanner;

const BannerMain = styled.div`
  overflow: hidden;
  position: relative;
  height: 550px;
  width: 100%;
  z-index: 100;

  .card {
    height: 600px;

    .pattern {
      position: absolute;
      left: -1%;
      top: 58%;
      z-index: 80;
    }
    .data {
      position: absolute;
      top: 15%;
      left: 3%;
      z-index: 10000;

      h4 {
        color: #fff;
        font-size: 27px;
        line-height: 27px;
        font-weight: 600;
        margin: 20px 0px;
        margin-left: 15px;
        width: 100%;
      }
      h5 {
        color: #fff;
        font-size: 42px;
        line-height: 50px;
        font-weight: 600;
        margin-left: 15px;
        width: 100%;
        margin-top: -0.5rem;
        margin-bottom: 20px;
      }
      p {
        width: 100%;
        margin-left: 15px;
        color: #fff;
        font-size: 17px;
        line-height: 28px;

        strong {
          font-size: 20px;
        }
      }
      @media only screen and (max-width: 991px) {
        top: 7%;

        h4 {
          font-size: 17px;
          line-height: 18px;
        }
        h5 {
          font-size: 25px;
          line-height: 32px;
          width: 90%;
        }
        p {
          width: 50%;
        }
      }
    }
    .sub_title {
      margin-left: 15px;
      border-radius: 2px;
      padding: 10.5px 7px;
      background-color: #052648;
      color: #fff;
      font-size: 18px;
      text-align: left;
      width: 550px;

      span {
        color: var(--primary-color);
      }
      @media only screen and (max-width: 991px) {
        font-size: 13px;
        padding-left: 7px;

        span {
          margin: 0px 5px;
        }
      }
    }
    .btnContainer {
      margin: 25px 0px;
      margin-left: 15px;

      a {
        margin-bottom: 0.7rem;
      }
    }
    .overlay {
      position: absolute;
      top: 0px;
      right: 35%;
      bottom: 0;
      left: 0;
      z-index: 9;
      background: linear-gradient(
        90deg,
        rgba(4, 33, 63, 1) 80%,
        rgba(4, 33, 63, 0) 100%
      );
      /* background: linear-gradient(90deg, rgba(255, 255, 255, 1) 80%, rgba(255, 255, 255, 0) 100%); */
      margin: 0;
      width: 75%;
      height: inherit;

      @media only screen and (max-width: 991px) {
        background-color: rgb(4, 33, 63);
        width: 100%;
      }
    }
  }
  .div_img {
    height: 550px;
    width: 700px;
    .hero_img {
      position: absolute;
      top: 0%;
      right: 0%;
      height: 550px;
      width: 600px;
      object-fit: cover;
    }
  }
  @media only screen and (max-width: 991px) {
    margin-top: 5rem;
  }
`;
