import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import {
  sliderBg,
  bage,
  client1,
  client2,
  client3,
  client4,
  client5,
  client6,
} from "../Utils/Images";
import { SliderMain, SliderGrid, LogoGrid } from "../Utils/CompoStyles";
import { MdKeyboardDoubleArrowRight } from "../Utils/Icons";
import { Link } from "react-router-dom";
import { Autoplay } from "swiper/modules";
import BannerCompo from "../components/BannerCompo";
import { useAPI } from "../context/getContext";
import Navbar from "../components/Navbar";

const Board = () => {
  const { buildersData, contactData } = useAPI();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>RTH Redevelopment - Builders On Board</title>
        <link rel="icon" id="faviconIcon" href={contactData.favicon} />
      </Helmet>
      <Navbar
        link1="link link--metis"
        link2="link link--metis"
        link3="link link--metis"
        link4="link"
        link5="link link--metis"
        link6="link link--metis"
      />
      <BannerCompo
        title="Builders"
        titleName="Builders On Board"
        headline="Builders On"
        subhead="Board"
        img="https://images.unsplash.com/photo-1582883545851-725a3b9502ce?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=870&q=80"
      />
      <SliderMain>
        <img src={sliderBg} alt="" className="bgService" />
        <div className="flex-center">
          <LogoGrid>
            {buildersData.map((item) => {
              return (
                <div className="card">
                  <img src={item.image} alt="" />
                  <div className="data">
                    <h4>{item.title}</h4>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item.description,
                      }}
                    />
                    {/* <ul className="listData">
                    <li>Establishment Year : 2000</li>
                    <li>Total Completed Project : 2000</li>
                    <li>Total Ongoing project : 2000</li>
                    <li>Preferred Area for Redevelopment : Kothrud, Pune</li>
                    <li>
                      Email ID :{" "}
                      <a href="mailto:xyz@gmail.com">xyz@gmail.com</a>
                    </li>
                    <li>
                      Website :
                      <a href="#" target="_blank">
                        www.dummyweb.com
                      </a>
                    </li>
                  </ul> */}
                  </div>
                </div>
              );
            })}
            {/* <div className="card">
              <img src={client2} alt="" />
              <div className="data">
                <h4>Joshi Buildcon Landmarks</h4>
                <ul className="listData">
                  <li>Establishment Year : 2000</li>
                  <li>Total Completed Project : 2000</li>
                  <li>Total Ongoing project : 2000</li>
                  <li>Preferred Area for Redevelopment : Kothrud, Pune</li>
                  <li>
                    Email ID : <a href="mailto:xyz@gmail.com">xyz@gmail.com</a>
                  </li>
                  <li>
                    Website :
                    <a href="#" target="_blank">
                      www.dummyweb.com
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="card">
              <img src={client3} alt="" />
              <div className="data">
                <h4>Kher Laghate Constructions</h4>
                <ul className="listData">
                  <li>Establishment Year : 2000</li>
                  <li>Total Completed Project : 2000</li>
                  <li>Total Ongoing project : 2000</li>
                  <li>Preferred Area for Redevelopment : Kothrud, Pune</li>
                  <li>
                    Email ID : <a href="mailto:xyz@gmail.com">xyz@gmail.com</a>
                  </li>
                  <li>
                    Website :
                    <a href="#" target="_blank">
                      www.dummyweb.com
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="card">
              <img src={client4} alt="" />
              <div className="data">
                <h4>Gangotree Greenbuild</h4>
                <ul className="listData">
                  <li>Establishment Year : 2000</li>
                  <li>Total Completed Project : 2000</li>
                  <li>Total Ongoing project : 2000</li>
                  <li>Preferred Area for Redevelopment : Kothrud, Pune</li>
                  <li>
                    Email ID : <a href="mailto:xyz@gmail.com">xyz@gmail.com</a>
                  </li>
                  <li>
                    Website :
                    <a href="#" target="_blank">
                      www.dummyweb.com
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="card">
              <img src={client5} alt="" />
              <div className="data">
                <h4>Trisha Developers</h4>
                <ul className="listData">
                  <li>Establishment Year : 2000</li>
                  <li>Total Completed Project : 2000</li>
                  <li>Total Ongoing project : 2000</li>
                  <li>Preferred Area for Redevelopment : Kothrud, Pune</li>
                  <li>
                    Email ID : <a href="mailto:xyz@gmail.com">xyz@gmail.com</a>
                  </li>
                  <li>
                    Website :
                    <a href="#" target="_blank">
                      www.dummyweb.com
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="card">
              <img src={client6} alt="" />
              <div className="data">
                <h4>Siddhesh Developers</h4>
                <ul className="listData">
                  <li>Establishment Year : 2000</li>
                  <li>Total Completed Project : 2000</li>
                  <li>Total Ongoing project : 2000</li>
                  <li>Preferred Area for Redevelopment : Kothrud, Pune</li>
                  <li>
                    Email ID : <a href="mailto:xyz@gmail.com">xyz@gmail.com</a>
                  </li>
                  <li>
                    Website :
                    <a href="#" target="_blank">
                      www.dummyweb.com
                    </a>
                  </li>
                </ul>
              </div>
            </div> */}
          </LogoGrid>
        </div>
        {/* <SliderGrid className="flex-center">
        <Swiper
          slidesPerView={1}
          spaceBetween={30}
          loop={true}
          className="mySwiper"
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          breakpoints={{
            640: {
              slidesPerView: 1,
            },
            768: {
              slidesPerView: 2,
            },
            1024: {
              slidesPerView: 5,
            },
          }}
          modules={[Autoplay]}
        >
          <SwiperSlide>
            <img src={logo1} alt="" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={logo2} alt="" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={logo3} alt="" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={logo4} alt="" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={logo5} alt="" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={logo3} alt="" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={logo1} alt="" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={logo4} alt="" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={logo3} alt="" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={logo5} alt="" />
          </SwiperSlide>
        </Swiper>
      </SliderGrid> */}
        {/* <div className="flex-center btn">
          <Link
            to="/builders-on-board"
            className="theme_btn btn-primary flex-center"
          >
            Read More
            <MdKeyboardDoubleArrowRight className="iconRight" />
          </Link>
        </div> */}
      </SliderMain>
    </div>
  );
};

export default Board;
