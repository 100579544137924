import React from "react";
import styled from "styled-components";
import { bage, arrow } from "../Utils/Images";
import { AiOutlineCloudServer, AiOutlineSecurityScan } from "react-icons/ai";
import { TbReportAnalytics, TbChecklist, TbCrane } from "react-icons/tb";
import { FcProcess } from "react-icons/fc";
import { IoDocumentsOutline } from "react-icons/io5";
import { VscServerProcess } from "react-icons/vsc";
import { useAPI } from "../context/getContext";

const Process = () => {
  const { workingData, contactData } = useAPI();

  return (
    <ProcessContainer>
      <div>
        <div className="labelMain">
          <img src={bage} alt="" />
          <h4>Working Process</h4>
        </div>
        <h2>Our Working Process</h2>
      </div>
      <div className="grid">
        <img src={arrow} alt="" className="arrow1" />
        <img src={arrow} alt="" className="arrow2" />
        <img src={arrow} alt="" className="arrow3" />
        <img src={arrow} alt="" className="arrow4" />
        {workingData.map((item, index) => {
          return (
            <div key={index}>
              {index == 0 ? (
                <div className="card flex-center flex-col">
                  <div className="icon_widget border2">
                    <div className="icons">
                      <img src={item.image} alt="" />
                    </div>
                  </div>
                  <h3>{item.title}</h3>
                </div>
              ) : null}
              {index == 1 ? (
                <div className="card flex-center flex-col">
                  <div className="icon_widget border1">
                    <div className="icons">
                      <img src={item.image} alt="" />
                    </div>
                  </div>
                  <h3>{item.title}</h3>
                </div>
              ) : null}
              {index == 2 ? (
                <div className="card flex-center flex-col">
                  <div className="icon_widget border3">
                    <div className="icons">
                      <img src={item.image} alt="" />
                    </div>
                  </div>
                  <h3>{item.title}</h3>
                </div>
              ) : null}
              {index == 3 ? (
                <div className="card flex-center flex-col">
                  <div className="icon_widget border4">
                    <div className="icons">
                      <img src={item.image} alt="" />
                    </div>
                  </div>
                  <h3>{item.title}</h3>
                </div>
              ) : null}
              {index == 4 ? (
                <div className="card flex-center flex-col">
                  <div className="icon_widget border5">
                    <div className="icons">
                      <img src={item.image} alt="" />
                    </div>
                  </div>
                  <h3>{item.title}</h3>
                </div>
              ) : null}
            </div>
          );
        })}
        {/* <div className="card flex-center flex-col">
          <div className="icon_widget border2">
            <div className="icons">
              <TbReportAnalytics className="ico2" />
            </div>
          </div>
          <h3>Feasibility Report</h3>
        </div>
        <div className="card flex-center flex-col">
          <div className="icon_widget border3">
            <div className="icons">
              <VscServerProcess className="ico3" />
            </div>
          </div>
          <h3>Tendering Process</h3>
        </div>
        <div className="card flex-center flex-col">
          <div className="icon_widget border4">
            <div className="icons">
              <TbChecklist className="ico4" />
            </div>
          </div>
          <h3 className="head">Finalisation of Developer</h3>
        </div>
        <div className="card flex-center flex-col">
          <div className="icon_widget border5">
            <div className="icons">
              <TbCrane className="ico5" />
            </div>
          </div>
          <h3>Construction Stage</h3>
        </div> */}
      </div>
    </ProcessContainer>
  );
};

export default Process;

const ProcessContainer = styled.div`
  position: relative;

  .labelMain {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 30px;

    h4 {
      color: var(--primary-color);
      font-weight: 500;
      font-size: 19px;
      margin-left: 15px;
    }
  }
  h2 {
    text-align: center;
    font-size: 2.8rem;
    font-weight: 600;
    color: #0e1e36;

    @media only screen and (max-width: 991px) {
      font-size: 2rem;
    }
  }
  .grid {
    display: grid;
    grid-template-columns: repeat(5, 2fr);
    grid-gap: 1rem;
    margin: 0px 50px;
    margin-top: 4rem;
    margin-bottom: 2rem;
    position: relative;

    .arrow1 {
      position: absolute;
      top: 35%;
      transform: translateY(-65%);
      left: 18%;
    }
    .arrow2 {
      position: absolute;
      top: 35%;
      transform: translateY(-65%);
      left: 38%;
    }
    .arrow3 {
      position: absolute;
      top: 35%;
      transform: translateY(-65%);
      left: 58%;
    }
    .arrow4 {
      position: absolute;
      top: 35%;
      transform: translateY(-65%);
      left: 78.5%;
    }

    .card {
      text-align: center;
      color: #243342;
      h3 {
        margin-bottom: 15px;
        font-size: 18px;
      }
    }
    .border1 {
      border: 2px dashed #eb641b;
    }
    .border2 {
      border: 2px dashed rgb(154, 205, 50);
    }
    .border3 {
      border: 2px dashed #2fc529;
    }
    .border4 {
      border: 2px dashed #32b094;
    }
    .border5 {
      border: 2px dashed #39639d;
    }
    .ico1 {
      color: #eb641b;
    }
    .ico2 {
      color: #bdda20;
    }
    .ico3 {
      color: #2fc529 !important;
    }
    .ico4 {
      color: #32b094 !important;
    }
    .ico5 {
      color: #39639d !important;
    }
    .icon_widget {
      margin: 0 auto;
      width: 130px;
      height: 130px;
      border-radius: 50%;
      background-color: transparent;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      margin-bottom: 30px;

      .icons {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        background: #ffffff;
        height: 100px;
        width: 100px;
        font-size: 50px;
        border-radius: 50%;
        -webkit-box-shadow: 0px 0px 10px rgba(64, 105, 255, 0.15);
        box-shadow: 0px 0px 10px rgba(64, 105, 255, 0.15);

        img {
          height: 40px;
          width: 40px;
        }
      }
    }
    @media only screen and (max-width: 991px) {
      grid-template-columns: 1fr;
      grid-row-gap: 2rem;
      h2 {
        font-size: 1.9rem;
      }
      .arrow1,
      .arrow2,
      .arrow3,
      .arrow4 {
        display: none;
      }
    }
  }
`;
