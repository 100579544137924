import React, { useState } from "react";
import { MdKeyboardArrowUp } from "react-icons/md";
import styled from "styled-components";

const ScrollTop = () => {
  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true);
    } else if (scrolled <= 300) {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  window.addEventListener("scroll", toggleVisible);

  return (
    <ScrolltopMain className="flex-center" onClick={scrollToTop}>
      <MdKeyboardArrowUp className="icon" />
    </ScrolltopMain>
  );
};

export default ScrollTop;

const ScrolltopMain = styled.div`
  position: fixed;
  bottom: 3%;
  right: 1.5%;
  width: 40px;
  height: 40px;
  background-color: #1378be;
  border-radius: 50%;
  cursor: pointer;
  z-index: 10;

  .icon {
    color: #fff;
    font-size: 30px;
  }
`;
