import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { MdKeyboardDoubleArrowRight } from "../Utils/Icons";
import { MdRefresh } from "react-icons/md";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";
import { toast } from "react-toastify";
import swal from "sweetalert";
import { useAPI } from "../context/getContext";

const ConsultancyForm = () => {
  const { captchaData } = useAPI();

  const [verfied, setVerifed] = useState(false);
  function onChange(value) {
    // console.log("Captcha value:", value);
    setVerifed(true);
  }

  const [prefix, setPrefix] = useState("");
  const [name, setName] = useState("");
  const [designation, setDesignation] = useState("");
  const [contact, setContact] = useState("");
  const [whatsappNo, setWhatsappNo] = useState("");
  const [email, setEmail] = useState("");
  const [buildingName, setBuildingName] = useState("");
  const [buildingType, setBuildingType] = useState("");
  const [address, setAddress] = useState("");
  const [parentSociety, setParentSociety] = useState("");
  const [surveyNo, setSurveyNo] = useState("");
  const [plotArea, setPlotArea] = useState("");
  const [tenamentType, setTenamentType] = useState("");
  const [tenamentNo, setTenamentNo] = useState("");
  const [roadWidth, setRoadWidth] = useState("");
  const [zone, setZone] = useState("");
  const [roadwidening, setRoadwidening] = useState("");
  const [litigation, setLitigation] = useState("");
  const [reservation, setReservation] = useState("");
  const [flatTenaments, setFlatTenaments] = useState("");
  const [shopTenaments, setShopTenaments] = useState("");
  const [officeTenaments, setOfficeTenaments] = useState("");
  const [garageTenaments, setGarageTenaments] = useState("");
  const [currentArea, setCurrentArea] = useState("");

  const consultancyValue = {
    prefix,
    name,
    designation,
    contact,
    whatsappNo,
    email,
    buildingName,
    buildingType,
    address,
    parentSociety,
    surveyNo,
    flatTenaments,
    shopTenaments,
    officeTenaments,
    garageTenaments,
    currentArea,
    plotArea,
    roadWidth,
    zone,
    roadwidening,
    litigation,
    reservation,
  };

  const options = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };

  const consultancyForm = async (e) => {
    try {
      if (
        !prefix ||
        !name ||
        !designation ||
        !contact ||
        !whatsappNo ||
        !email ||
        !buildingName ||
        !buildingType ||
        !address ||
        !parentSociety ||
        !surveyNo ||
        !plotArea ||
        !tenamentType ||
        !tenamentNo ||
        !roadWidth ||
        !zone ||
        !roadwidening ||
        !litigation ||
        !reservation ||
        !currentArea ||
        !flatTenaments ||
        !shopTenaments ||
        !officeTenaments ||
        !garageTenaments
      ) {
        return toast.error("All fields are required");
      }
      if (verfied === false) {
        toast.error("Complete the Captcha");
      }
      if (prefix === "") {
        toast.error("Enter a prefix");
      }
      if (name === "") {
        toast.error("Enter your name");
      }
      if (designation === "") {
        toast.error("Enter your designation");
      }
      if (contact === "") {
        toast.error("Enter your contact no");
      }
      if (whatsappNo === "") {
        toast.error("Enter your whatsapp no");
      }
      if (contact.length > 10) {
        toast.error("Enter a valid contact no");
      }
      if (whatsappNo.length > 10) {
        toast.error("Enter a valid whatsapp no");
      }
      if (email === "") {
        toast.error("Enter a valid email id");
      }
      if (buildingName === "") {
        toast.error("Enter building name");
      }
      if (buildingType === "") {
        toast.error("Enter building type");
      }
      if (address === "") {
        toast.error("Enter your address");
      }
      if (parentSociety === "") {
        toast.error("Enter name of parent society");
      }
      if (surveyNo === "") {
        toast.error("Enter your survey no");
      }
      if (plotArea === "") {
        toast.error("Enter your plot area");
      }
      if (currentArea === "") {
        toast.error("Enter your total current area of all tenements");
      }
      if (flatTenaments === "") {
        toast.error("Enter your flat tenements");
      }
      if (shopTenaments === "") {
        toast.error("Enter your shop tenements");
      }
      if (officeTenaments === "") {
        toast.error("Enter your office tenements");
      }
      if (garageTenaments === "") {
        toast.error("Enter your garage tenements");
      }
      if (roadWidth === "") {
        toast.error("Enter your aprroach road width");
      }
      if (zone === "") {
        toast.error("Enter your zone");
      }
      if (roadwidening === "") {
        toast.error("Enter road widening");
      }
      if (litigation === "") {
        toast.error("Enter your litigation");
      }
      if (reservation === "") {
        toast.error("Enter your reservation");
      }
      const res = await axios.post(
        "https://rthredevelopment.com/admin/api/SubmitEnquiry",
        consultancyValue,
        options
      );
      if (res.data.RESPONSE_MSG === "Enquiry Submitted Successfully.") {
        swal({ title: res.data.RESPONSE_MSG, icon: "success", button: "Ok" });
      }
      console.log(res);
      setPrefix("");
      setName("");
      setDesignation("");
      setContact("");
      setWhatsappNo("");
      setEmail("");
      setBuildingName("");
      setBuildingType("");
      setAddress("");
      setParentSociety("");
      setSurveyNo("");
      setPlotArea("");
      setTenamentType("");
      setTenamentNo("");
      setRoadWidth("");
      setZone("");
      setRoadwidening("");
      setLitigation("");
      setReservation("");
      setCurrentArea("");
      setFlatTenaments("");
      setShopTenaments("");
      setOfficeTenaments("");
      setGarageTenaments("");
    } catch (error) {
      console.log(error);
    }
  };

  const resetForm = () => {
    setPrefix("");
    setName("");
    setDesignation("");
    setContact("");
    setWhatsappNo("");
    setEmail("");
    setBuildingName("");
    setBuildingType("");
    setAddress("");
    setParentSociety("");
    setSurveyNo("");
    setPlotArea("");
    setTenamentType("");
    setTenamentNo("");
    setRoadWidth("");
    setZone("");
    setRoadwidening("");
    setLitigation("");
    setReservation("");
    setCurrentArea("");
    setFlatTenaments("");
    setShopTenaments("");
    setOfficeTenaments("");
    setGarageTenaments("");
  };

  return (
    <FormMain>
      <h2>Personal Information</h2>
      <form>
        <div className="grid">
          <div>
            <label>Name*</label>
            <div className="flex-start">
              <select
                className="default-input"
                value={prefix}
                onChange={(e) => setPrefix(e.target.value)}
              >
                <option>-- Prefix --</option>
                <option>Mr.</option>
                <option>Mrs.</option>
              </select>
              <input
                type="text"
                placeholder=""
                className="grid-input"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
          </div>
          <div>
            <label>Designation in Society*</label>
            <div className="flex-start">
              <select
                className="full-select"
                value={designation}
                onChange={(e) => setDesignation(e.target.value)}
              >
                <option>-- Select --</option>
                <option>Scretary</option>
                <option>Chairman</option>
                <option>Treasurer</option>
                <option>Committee Member</option>
                <option>Member</option>
              </select>
            </div>
          </div>
        </div>
        <div className="gridCol3">
          <div>
            <label>Contact Number*</label>
            <div className="flex-start">
              <input
                type="text"
                placeholder=""
                className="grid-input"
                value={contact}
                onChange={(e) => setContact(e.target.value)}
                pattern="[1-9]{1}[0-9]{9}"
                maxLength={10}
              />
            </div>
          </div>
          <div>
            <label>Whatsapp Number*</label>
            <div className="flex-start">
              <input
                type="text"
                placeholder=""
                className="grid-input"
                value={whatsappNo}
                onChange={(e) => setWhatsappNo(e.target.value)}
                pattern="[1-9]{1}[0-9]{9}"
                maxLength={10}
              />
            </div>
          </div>
          <div>
            <label>Email ID*</label>
            <div className="flex-start">
              <input
                type="text"
                placeholder=""
                className="grid-input"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
          </div>
        </div>
        <h2>Building Information</h2>
        <div className="grid">
          <div>
            <label>Building Name*</label>
            <div className="flex-start">
              <input
                type="text"
                placeholder=""
                className="grid-input"
                value={buildingName}
                onChange={(e) => setBuildingName(e.target.value)}
              />
            </div>
          </div>
          {/* <div>
            <label>Type Of Building*</label>
            <div className="flex-start">
              <select
                className="full-select"
                value={buildingType}
                onChange={(e) => setBuildingType(e.target.value)}
              >
                <option>-- Select --</option>
                <option>Cooperative Housing Society</option>
                <option>Apartment</option>
              </select>
            </div>
          </div> */}
          <div>
            <label>Detail Address*</label>
            <div className="flex-start">
              <input
                type="text"
                placeholder=""
                className="grid-input"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              />
            </div>
          </div>
          <div>
            <label>Name of Parent Society (if any)</label>
            <div className="flex-start">
              <input
                type="text"
                placeholder=""
                className="grid-input"
                value={parentSociety}
                onChange={(e) => setParentSociety(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="gridCol3">
          <div>
            <label>Type Of Building*</label>
            <div className="flex-start">
              <select
                className="full-select"
                value={buildingType}
                onChange={(e) => setBuildingType(e.target.value)}
              >
                <option>-- Select --</option>
                <option>Cooperative Housing Society</option>
                <option>Apartment</option>
              </select>
            </div>
          </div>
          <div>
            <label>CTS / Survey No.</label>
            <div className="flex-start">
              <input
                type="text"
                placeholder=""
                className="grid-input"
                value={surveyNo}
                onChange={(e) => setSurveyNo(e.target.value)}
              />
            </div>
          </div>
          <div>
            <label>Plot Area*</label>
            <div className="flex-start">
              <input
                type="text"
                placeholder=""
                className="grid-input"
                value={plotArea}
                onChange={(e) => setPlotArea(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="gridCol4 m1">
          <div>
            <label>Total No. Of Tenaments</label>
            <div className="flex-start">
              <input type="text" value="Flats" className="small-select" />
              <input
                type="text"
                placeholder=""
                className="grid-input"
                value={flatTenaments}
                onChange={(e) => setFlatTenaments(e.target.value)}
              />
            </div>
          </div>
          <div>
            <div className="flex-start mt1">
              <input type="text" value="Shops" className="small-select" />
              <input
                type="text"
                placeholder=""
                className="grid-input"
                value={shopTenaments}
                onChange={(e) => setShopTenaments(e.target.value)}
              />
            </div>
          </div>
          <div>
            <div className="flex-start mt1">
              <input type="text" value="Office" className="small-select" />
              <input
                type="text"
                placeholder=""
                className="grid-input"
                value={officeTenaments}
                onChange={(e) => setOfficeTenaments(e.target.value)}
              />
            </div>
          </div>
          <div>
            <div className="flex-start mt1">
              <input type="text" value="Garage" className="small-select" />
              <input
                type="text"
                placeholder=""
                className="grid-input"
                value={garageTenaments}
                onChange={(e) => setGarageTenaments(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="gridCol3 m1">
          <div>
            <label>Total current area of all tenements</label>
            <div className="flex-start">
              <input
                type="text"
                placeholder=""
                className="grid-input"
                value={currentArea}
                onChange={(e) => setCurrentArea(e.target.value)}
              />
            </div>
          </div>
          <div>
            <label>Approach Road Width</label>
            <div className="flex-start">
              <select
                className="full-select"
                value={roadWidth}
                onChange={(e) => setRoadWidth(e.target.value)}
              >
                <option>-- Select --</option>
                <option>Below 9m</option>
                <option>9 m upto 12 m</option>
                <option>12 m upto 18 m</option>
                <option>Above 18 m</option>
              </select>
            </div>
          </div>
          <div>
            <label>Zone</label>
            <div className="flex-start">
              <select
                className="full-select"
                value={zone}
                onChange={(e) => setZone(e.target.value)}
              >
                <option>-- Select --</option>
                <option>Congested Area (Gaothan)</option>
                <option>Non Congested Area</option>
                <option>TOD Zone</option>
              </select>
            </div>
          </div>
        </div>
        <div className="grid">
          <div>
            <label>Road Widening (if any)</label>
            <div className="flex-start">
              <input
                type="text"
                placeholder=""
                className="grid-input"
                value={roadwidening}
                onChange={(e) => setRoadwidening(e.target.value)}
              />
            </div>
          </div>
          <div>
            <label>Litigation if any</label>
            <div className="flex-start">
              <input
                type="text"
                placeholder=""
                className="grid-input"
                value={litigation}
                onChange={(e) => setLitigation(e.target.value)}
              />
            </div>
          </div>
          <div>
            <label>Reservation if any</label>
            <div className="flex-start">
              <input
                type="text"
                placeholder=""
                className="grid-input"
                value={reservation}
                onChange={(e) => setReservation(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="checkboxContainer">
          <div className="checkbox flex-start">
            <input type="checkbox" checked />
            <span>
              I Accept <Link to="/terms-condition">Terms & Conditions</Link>
            </span>
          </div>
          <>
            <ReCAPTCHA sitekey={captchaData.site_key} onChange={onChange} />
          </>
        </div>
        <div className="flex-center btn">
          <Link
            to="#"
            className="theme_btn btn-secondary flex-center"
            onClick={() => resetForm()}
          >
            Refresh
            <MdRefresh className="iconRight" />
          </Link>
          <Link
            to="#"
            className="theme_btn btn-primary flex-center"
            onClick={() => consultancyForm()}
          >
            Submit
            <MdKeyboardDoubleArrowRight className="iconRight" />
          </Link>
        </div>
      </form>
    </FormMain>
  );
};

export default ConsultancyForm;

const FormMain = styled.div`
  padding: 30px 40px;
  margin: 50px 80px;
  margin-top: 0px;
  background-color: #fff;
  box-shadow: 0px 10px 30px rgba(4, 33, 63, 0.05);

  .m1 {
    margin-bottom: 1rem;
  }
  .mt1 {
    padding-top: 1.65rem;
  }
  h2 {
    font-size: 30px;
    font-weight: 600;
    color: #1378be;
    margin-bottom: 1rem;
    margin-top: 2rem;
  }
  .small-select {
    padding: 7px 5px;
    outline: none;
    border: none;
    border: 1px solid grey;
    border-right: none;
    font-size: 15px;
    font-weight: 600;
    width: 70px;
    pointer-events: none;
    text-align: center;
    background-color: #1378be;
    /* color: #0e1e36; */
    color: #fff;
  }
  .default-input {
    padding: 7px 5px;
    outline: none;
    border: none;
    border: 1px solid grey;
    border-right: none;
    font-size: 15px;
    font-weight: 600;
    color: #0e1e36;
  }
  .grid-input,
  .full-select {
    padding: 7px 15px;
    outline: none;
    border: none;
    border: 1px solid grey;
    width: 350px;
    font-size: 15px;
    color: #0e1e36;
    font-weight: 600;
    width: 100%;
  }
  @media only screen and (max-width: 991px) {
    .grid-input,
    .full-select {
      width: 100%;
    }
  }

  .checkboxContainer {
    margin-top: 1rem;
    .checkbox {
      margin-right: 1rem;
      margin-bottom: 1.5rem;

      input {
        width: 20px;
        height: 20px;
        margin-right: 0.5rem;
      }
      span {
        font-weight: 600;
        color: #0e1e36;
        a {
          font-weight: 600;
          color: #0e1e36;
          border-bottom: 1px solid #0e1e36;
        }
      }
    }
  }

  form {
    label {
      font-size: 17px;
      color: #0e1e36;
      font-weight: 600;
    }
    .gridCol3 {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 1rem;
      margin-top: 1.2rem;

      div {
        margin-top: 0.2rem;
      }
      @media only screen and (max-width: 991px) {
        grid-template-columns: 1fr;

        div {
          margin-top: 0rem;
        }
      }
    }
    .gridCol4 {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 1rem;
      margin-top: 1.2rem;

      div {
        margin-top: 0.2rem;
      }
      @media only screen and (max-width: 991px) {
        grid-template-columns: 1fr;

        div {
          margin-top: 0rem;
        }
      }
    }
    .grid {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 1rem;

      div {
        margin-top: 0.2rem;
      }
      @media only screen and (max-width: 991px) {
        grid-template-columns: 1fr;

        div {
          margin-top: 0rem;
        }
      }
    }
    .btn {
      margin-top: 2rem;
    }
  }

  @media only screen and (max-width: 991px) {
    margin: 0px 0px;
  }
`;
