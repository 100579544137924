import React from "react";
import { ServicePopup } from "../../Utils/CompoStyles";
import { postConstruction } from "../../Utils/Images";
import { IoCloseCircleOutline } from "react-icons/io5";

const ServicesPopup5 = ({ setServicePopup5 }) => {
  return (
    <ServicePopup>
      <div className="overlay"></div>
      <div className="data">
        <div className="grid">
          <div className="card">
            <img src={postConstruction} alt="" />
          </div>
          <div className="card">
            <h3>Preconstruction Stage</h3>
            <ul>
              <li>Checking new Layouts</li>
              <li>Cross Checking Carpet Area</li>
              <li>Checking all Municipal Approvals</li>
              <li>Checking All Architectural & RCC Drawings</li>
              <li>Checking RERA Documentation</li>
              <li>Preparing Bar Chart</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="close" onClick={() => setServicePopup5(false)}>
        <IoCloseCircleOutline className="icon" />
      </div>
    </ServicePopup>
  );
};

export default ServicesPopup5;
