import React from "react";
import styled from "styled-components";
import { pattern1 } from "../Utils/Images";
import { useAPI } from "../context/getContext";

const BannerCompo = (props) => {
  const { contactData } = useAPI();
  return (
    <BannerMain>
      <div className="card">
        <div className="overlay"></div>
        <div className="data">
          <div className="transparent-text">{props.title}</div>
          <h3>{props.headline}</h3>
          <h4>{props.subhead}</h4>
          <h5>{props.subhead1}</h5>
          <p>
            Home
            <span className="partition">|</span>
            <span className="pageName">{props.titleName}</span>
          </p>
        </div>
        <img src={pattern1} alt="" className="pattern" />
      </div>
      <div className="div_img">
        <img src={contactData.slider_img} alt="" className="hero_img" />
      </div>
    </BannerMain>
  );
};

export default BannerCompo;

const BannerMain = styled.div`
  overflow: hidden;
  position: relative;
  height: 500px;
  width: 100%;
  z-index: 100;

  .card {
    height: 600px;

    .pattern {
      position: absolute;
      left: -1%;
      top: 58%;
      z-index: 80;
    }
    .data {
      position: absolute;
      top: 15%;
      left: 3%;
      z-index: 10000;

      h3 {
        color: #fff;
        font-size: 60px;
        line-height: 60px;
      }
      h4 {
        color: #fff;
        font-size: 60px;
        font-weight: 400;
      }
      h5 {
        color: #fff;
        font-size: 60px;
        font-weight: 400;
        line-height: 45px;
        margin-bottom: 0.5rem;
      }
      p {
        margin-top: 0.5rem;
        color: #fff;
        font-weight: 500;
        .partition {
          color: var(--primary-color);
          margin: 0px 10px;
        }
        .pageName {
          color: var(--primary-color);
        }
      }
      @media only screen and (max-width: 991px) {
        top: 7%;

        h3 {
          font-size: 45px;
          line-height: 48px;
        }
        h4 {
          color: #fff;
          font-size: 35px;
          font-weight: 400;
          line-height: 40px;
        }
        h5 {
          color: #fff;
          font-size: 35px;
          font-weight: 400;
          line-height: 32px;
          margin-top: 0.3rem;
        }
      }
    }
    .btnContainer {
      margin: 25px 0px;
      margin-left: 15px;

      a {
        margin-bottom: 0.7rem;
      }
    }
    .overlay {
      position: absolute;
      top: 0px;
      right: 35%;
      bottom: 0;
      left: 0;
      z-index: 9;
      background: linear-gradient(
        90deg,
        rgba(4, 33, 63, 1) 80%,
        rgba(4, 33, 63, 0) 100%
      );
      /* background: linear-gradient(90deg, rgba(255, 255, 255, 1) 80%, rgba(255, 255, 255, 0) 100%); */
      margin: 0;
      width: 75%;
      height: inherit;

      @media only screen and (max-width: 991px) {
        background-color: rgb(4, 33, 63);
        width: 100%;
      }
    }
  }
  .div_img {
    height: 500px;
    width: 700px;
    .hero_img {
      position: absolute;
      top: 0%;
      right: 0%;
      height: 500px;
      width: 600px;
      object-fit: cover;
    }
    @media only screen and (max-width: 991px) {
      display: none;
    }
  }
  .transparent-text {
    margin-bottom: -3.5rem;
    z-index: -1;
    opacity: 0.3;
    font-size: 128px;
    font-weight: 700;
    line-height: 100px;
    white-space: nowrap;
    -webkit-text-stroke: transparent;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-color: rgba(255, 255, 255, 0.3);

    @media only screen and (max-width: 991px) {
      font-size: 80px;
    }
  }
  @media only screen and (max-width: 991px) {
    height: 350px;
    margin-top: 5rem;
  }
`;
