import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { logo } from "../Utils/Images";
import { MdOutlineNotificationsActive, FaTelegramPlane } from "../Utils/Icons";
import { footerBg, rthLogo1 } from "../Utils/Images";

const Footer = () => {
  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true);
    } else if (scrolled <= 300) {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  window.addEventListener("scroll", toggleVisible);

  return (
    <div>
      {/* <FooterContact>
        <img src={footerBg} alt="" />
        <div className="overlay"></div>
        <div className="data flex-center flex-col">
          <h3>Feel Free To Contatct Us</h3>
          <p>
            Contrary to popular belief, Lorem Ipsum is not simply random text.
            It has roots in a piece of classical Latin literature from 45 BC,
            making it over 2000 years old.
          </p>
          <Link to="/contact" className="theme_btn btn-primary">
            Contact Us <FaTelegramPlane className="iconRight" />
          </Link>
        </div>
      </FooterContact> */}
      <FooterContainer>
        {/* <Border></Border>
        <FooterMain className="space-between">
          <div>
            <img src={rthLogo1} alt="" className="footerLogo" />
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua ut enim
              ad minim veniam.
            </p>
          </div>
          <div>
            <h5>
              <MdOutlineNotificationsActive className="iconBell" /> keep up to
              date - get updates with latest topics.
            </h5>
            <div className="inputContainer flex-center">
              <input type="text" placeholder="Enter your email address" />
              <div className="subscribe flex-center">
                Subscribe Now <FaTelegramPlane className="icon" />
              </div>
            </div>
          </div>
        </FooterMain>
        <Border></Border> */}
        <CopyFooter>
          <div className="data">
            <div>
              © 2023
              <Link to="/" onClick={scrollToTop}>
                RTH Redevelopment
              </Link>
            </div>
            <div>
              <span>|</span>Designed & Developed by
              <a href="https://applexinfotech.com/" target="_blank">
                Applex Infotech
              </a>
            </div>
          </div>
          <div className="flex-center data2">
            <Link to="/terms-condition">Terms & Conditions</Link>
            <p>|</p>
            <Link to="/privacy-policy">Privacy Policy</Link>
          </div>
        </CopyFooter>
      </FooterContainer>
    </div>
  );
};

export default Footer;

const FooterContainer = styled.div`
  background-color: #0e0a01;
  /* padding: 27px 10px; */
  z-index: 10000;
`;

const FooterContact = styled.div`
  position: relative;
  z-index: -1;
  img {
    width: 100%;
    margin-bottom: -22rem;
  }
  .overlay {
    position: absolute;
    top: 0%;
    z-index: 10;
    height: 100%;
    width: 100%;
    background: linear-gradient(
      180deg,
      rgba(14, 10, 1, 0) -75.09%,
      #0e0a01 75.92%
    );
  }
  .data {
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 15;
    text-align: center;
    color: #fff;

    h3 {
      font-size: 55px;
      margin-bottom: 20px;
    }
    p {
      font-size: 19px;
      line-height: 30px;
      margin-bottom: 35px;
    }
  }
`;

const FooterMain = styled.div`
  padding: 65px 10px;
  padding-top: 40px;

  div {
    img {
      margin-bottom: 0px;
      width: 280px;
      height: 80px;
    }
    p {
      color: #fff;
      width: 60%;
      line-height: 25px;
    }
    h5 {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      .iconBell {
        font-size: 35px;
        margin-right: 10px;
      }
      font-size: 18px;
      margin-bottom: 25px;
      color: #fff;
    }
    .inputContainer {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 550px;
      color: rgb(255, 255, 255);
      font-size: 16px;
      line-height: 19px;
      border-radius: 2px;
      background: #1e1b15 !important;
      padding: 21px 200px 21px 20px;
      border: none !important;

      input {
        width: 400px;
        color: #fff;
        background: #1e1b15 !important;
        border: none !important;
        outline: none;
        font-size: 16px;

        &::placeholder {
          color: #fff;
          font-size: 16px;
        }
      }
      .subscribe {
        display: inline-block;
        position: absolute;
        top: calc(50% - 21.5px);
        right: 9px;
        border: 0;
        padding: 9.5px 18px;
        color: #fff;
        font-size: 15px;
        font-weight: 500;
        font-family: "Poppins", sans-serif;
        line-height: 22px;
        box-sizing: border-box;
        border-radius: 2px;
        transition: all 0.4s ease-in-out;
        -webkit-box-sizing: border-box;
        -webkit-transition: all 0.4s ease-in-out;
        background-color: var(--primary-color);

        .icon {
          margin-left: 5px;
          margin-bottom: -3px;
          font-size: 20px;
        }
      }
    }
  }
`;

const Border = styled.div`
  background-color: rgba(255, 255, 255, 0.1);
  width: 100%;
  height: 2px;
  padding: 0px 15px;
  overflow: hidden;
`;

const CopyFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 10px;
  color: #fff;

  .border {
  }
  .data {
    display: flex;
    align-items: center;
    justify-content: center;
    a {
      margin: 0px 5px;
      color: var(--secondary-color);
      font-size: 17px;
    }
    span {
      margin-right: 7px;
      margin-left: 2px;
    }
    p {
      margin: 0px 7px;
    }
  }
  .data2 {
    a {
      margin: 0px 5px;
      color: var(--secondary-color);
      font-size: 17px;
    }
  }
  @media only screen and (max-width: 991px) {
    justify-content: center;
    flex-direction: column;
    .data {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      div {
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      span {
        display: none;
      }
    }
    .data2 {
      margin-top: 0.2rem;
      a {
        margin: 0px 10px;
      }
    }
  }
`;
