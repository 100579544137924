import React from "react";
import styled from "styled-components";
import { IoCloseCircleOutline } from "react-icons/io5";

const Popup = ({ setPopup }) => {
  return (
    <PopupMain>
      <div className="overlay"></div>
      <div className="data">
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/15meenW-Z9E?si=PziY268Fbmt9qzpi"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
      </div>
      <div className="close" onClick={() => setPopup(false)}>
        <IoCloseCircleOutline className="icon" />
      </div>
    </PopupMain>
  );
};

export default Popup;

const PopupMain = styled.div`
  .overlay {
    position: fixed;
    top: 0%;
    left: 0%;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 100005;
  }
  .data {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 800px;
    height: 500px;
    background-color: #fff;
    z-index: 100008;
    overflow: hidden;

    iframe {
      width: 800px;
      height: 500px;
      padding: 15px;
    }
  }
  .close {
    position: fixed;
    top: 10%;
    right: 5%;
    z-index: 100008;
    cursor: pointer;

    .icon {
      color: #fff;
      font-size: 45px;
    }
  }
  @media only screen and (max-width: 991px) {
    .data {
      width: 100%;
      height: 500px;

      iframe {
        width: 100%;
        height: 500px;
        padding: 15px;
      }
    }
  }
`;
