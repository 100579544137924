import React from "react";
import { ServicePopup } from "../../Utils/CompoStyles";
import { tendor } from "../../Utils/Images";
import { IoCloseCircleOutline } from "react-icons/io5";

const ServicesPopup2 = ({ setServicePopup2 }) => {
  return (
    <ServicePopup>
      <div className="overlay"></div>
      <div className="data">
        <div className="grid">
          <div className="card">
            <img src={tendor} alt="" />
          </div>
          <div className="card">
            <h3>Tendering Process</h3>
            <ul>
              <li>Finalizing Internal Specifications</li>
              <li>Finalizing Amenity List</li>
              <li>Finalizing Funds to be demanded from developer</li>
              <li>Finalizing specific requirements of society members</li>
              <li>
                Preparing forms for developer to gather his complete information
              </li>
              <li>Preparing Conditions of Contract</li>
              <li>Inviting Developers for Tendering Process</li>
              <li>Comparative Analysis of received tenders</li>
              <li>Short listing of developers</li>
              <li>Site Visit of projects of shortlisted developers</li>
              <li>Recommendation of Top Two Developers</li>
              <li>
                Studding draft of MOU & Development Agreement from selected
                developer and giving suggestions to protect interest of society
                members
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="close" onClick={() => setServicePopup2(false)}>
        <IoCloseCircleOutline className="icon" />
      </div>
    </ServicePopup>
  );
};

export default ServicesPopup2;
