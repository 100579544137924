import React from "react";
import styled from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import {
  sliderBg,
  bage,
  client1,
  client2,
  client3,
  client4,
  client5,
  client6,
} from "../Utils/Images";
import { MdKeyboardDoubleArrowRight } from "../Utils/Icons";
import { Link } from "react-router-dom";
import { Autoplay } from "swiper/modules";
import { useAPI } from "../context/getContext";

const SliderCompo = ({ toggleNav }) => {
  const { buildersData } = useAPI();
  return (
    <SliderMain>
      <img src={sliderBg} alt="" className="bgService" />
      <div>
        <div className="labelMain">
          <img src={bage} alt="" />
          <h4>Builders On Board</h4>
        </div>
        <h2>Builders On Board</h2>
      </div>
      <div className="flex-center">
        <LogoGrid>
          {buildersData.slice(0, 4).map((item) => {
            return (
              <div className="card">
                  <img src={item.image} alt="" />
                <div className="data">
                  <h4>{item.title}</h4>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: item.description,
                    }}
                  />
                  {/* <ul className="listData">
                    <li>Establishment Year : 2000</li>
                    <li>Total Completed Project : 2000</li>
                    <li>Total Ongoing project : 2000</li>
                    <li>Preferred Area for Redevelopment : Kothrud, Pune</li>
                    <li>
                      Email ID :{" "}
                      <a href="mailto:xyz@gmail.com">xyz@gmail.com</a>
                    </li>
                    <li>
                      Website :
                      <a href="#" target="_blank">
                        www.dummyweb.com
                      </a>
                    </li>
                  </ul> */}
                </div>
              </div>
            );
          })}
          {/* <div className="card">
            <img src={client2} alt="" />
            <div className="data">
              <h4>Joshi Buildcon Landmarks</h4>
              <ul className="listData">
                <li>Establishment Year : 2000</li>
                <li>Total Completed Project : 2000</li>
                <li>Total Ongoing project : 2000</li>
                <li>Preferred Area for Redevelopment : Kothrud, Pune</li>
                <li>
                  Email ID : <a href="mailto:xyz@gmail.com">xyz@gmail.com</a>
                </li>
                <li>
                  Website :
                  <a href="#" target="_blank">
                    www.dummyweb.com
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="card">
            <img src={client3} alt="" />
            <div className="data">
              <h4>Kher Laghate Constructions</h4>
              <ul className="listData">
                <li>Establishment Year : 2000</li>
                <li>Total Completed Project : 2000</li>
                <li>Total Ongoing project : 2000</li>
                <li>Preferred Area for Redevelopment : Kothrud, Pune</li>
                <li>
                  Email ID : <a href="mailto:xyz@gmail.com">xyz@gmail.com</a>
                </li>
                <li>
                  Website :
                  <a href="#" target="_blank">
                    www.dummyweb.com
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="card">
            <img src={client4} alt="" />
            <div className="data">
              <h4>Gangotree Greenbuild</h4>
              <ul className="listData">
                <li>Establishment Year : 2000</li>
                <li>Total Completed Project : 2000</li>
                <li>Total Ongoing project : 2000</li>
                <li>Preferred Area for Redevelopment : Kothrud, Pune</li>
                <li>
                  Email ID : <a href="mailto:xyz@gmail.com">xyz@gmail.com</a>
                </li>
                <li>
                  Website :
                  <a href="#" target="_blank">
                    www.dummyweb.com
                  </a>
                </li>
              </ul>
            </div>
          </div> */}
          {/* <div className="card">
            <img src={client5} alt="" />
            <div className="data">
              <h4>Trisha Developers</h4>
              <ul className="listData">
                <li>Establishment Year : 2000</li>
                <li>Total Completed Project : 2000</li>
                <li>Total Ongoing project : 2000</li>
                <li>Preferred Area for Redevelopment : Kothrud, Pune</li>
                <li>Email ID : xyz@gmail.com</li>
                <li>
                  Website :{" "}
                  <a href="#" target="_blank">
                    www.dummyweb.com
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="card">
            <img src={client6} alt="" />
            <div className="data">
              <h4>Siddhesh Developers</h4>
              <ul className="listData">
                <li>Establishment Year : 2000</li>
                <li>Total Completed Project : 2000</li>
                <li>Total Ongoing project : 2000</li>
                <li>Preferred Area for Redevelopment : Kothrud, Pune</li>
                <li>Email ID : xyz@gmail.com</li>
                <li>
                  Website :{" "}
                  <a href="#" target="_blank">
                    www.dummyweb.com
                  </a>
                </li>
              </ul>
            </div>
          </div> */}
        </LogoGrid>
      </div>
      {/* <SliderGrid className="flex-center">
        <Swiper
          slidesPerView={1}
          spaceBetween={30}
          loop={true}
          className="mySwiper"
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          breakpoints={{
            640: {
              slidesPerView: 1,
            },
            768: {
              slidesPerView: 2,
            },
            1024: {
              slidesPerView: 5,
            },
          }}
          modules={[Autoplay]}
        >
          <SwiperSlide>
            <img src={logo1} alt="" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={logo2} alt="" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={logo3} alt="" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={logo4} alt="" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={logo5} alt="" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={logo3} alt="" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={logo1} alt="" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={logo4} alt="" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={logo3} alt="" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={logo5} alt="" />
          </SwiperSlide>
        </Swiper>
      </SliderGrid> */}
      <div
        style={{ marginTop: "-1.6rem" }}
        className="flex-center btn"
        onClick={() => toggleNav(4)}
      >
        <Link
          to="/builders-on-board"
          className="theme_btn btn-primary flex-center"
        >
          Read More
          <MdKeyboardDoubleArrowRight className="iconRight" />
        </Link>
      </div>
    </SliderMain>
  );
};

export default SliderCompo;

const SliderMain = styled.div`
  position: relative;

  .bgService {
    position: absolute;
    top: 0%;
    z-index: -1;
    height: 1270px;
    width: 100%;
  }
  .labelMain {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 70px;

    h4 {
      color: var(--primary-color);
      font-weight: 500;
      font-size: 19px;
      margin-left: 15px;
    }
  }
  h2 {
    text-align: center;
    font-size: 2.8rem;
    font-weight: 600;
    color: #0e1e36;
  }
  .btn {
    margin-bottom: 3.5rem;
  }
  @media only screen and (max-width: 991px) {
    h2 {
      font-size: 2rem;
    }
  }
`;

const SliderGrid = styled.div`
  padding: 15px 0px;
  border-radius: 4px;
  margin: 0px 30px;
  margin-top: 4rem;
  margin-bottom: 4.5rem;

  div {
    img {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: auto;
      height: 100px;
      width: 170px;
    }
  }
`;

const LogoGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 1rem;
  margin-top: 4rem;
  margin-bottom: 4.5rem;
  margin-left: 1rem;
  margin-right: 1rem;

  .card {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    background-color: #fff;
    -webkit-box-shadow: 0px 2px 32px rgba(14, 10, 1, 0.05);
    box-shadow: 0px 2px 32px rgba(14, 10, 1, 0.05);
    border: 1px solid #1378be;
    padding: 15px;
    height: 470px;
    width: 315px;

    .data {
      border-top: 1px solid rgba(19, 120, 190, 0.4);
    }
    img {
      position: absolute;
      top: 30%;
      left: 50%;
      transform: translate(-50%, -70%);
      display: flex;
      align-items: center;
      justify-content: center;
      margin: auto;
      width: 250px;
      max-width: 100%;
      height: auto;
    }
    h4 {
      position: absolute;
      top: 41%;
      left: 50%;
      transform: translateX(-50%);
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      margin-top: 1rem;
      line-height: 22px;
      font-size: 20px;
      color: #0e1e36;
      margin-top: 1rem;
    }
    p {
      text-align: left;
    }
    .listData {
      position: absolute;
      top: 52%;
      left: 50%;
      transform: translateX(-50%);
      width: 100%;
      padding: 15px;
      list-style: disc;
      margin-left: 1rem;
      li {
        font-size: 15px;
        font-weight: 500;
        color: #0e1e36;

        a {
          color: darkblue;
        }
      }
    }
  }
  @media only screen and (max-width: 991px) {
    grid-template-columns: 1fr;
  }
`;
