import React from "react";
import { ServicePopup } from "../../Utils/CompoStyles";
import { documents } from "../../Utils/Images";
import { IoCloseCircleOutline } from "react-icons/io5";

const ServicePopup6 = ({ setServicePopup6 }) => {
  return (
    <ServicePopup>
      <div className="overlay"></div>
      <div className="data">
        <div className="grid">
          <div className="card">
            <img src={documents} alt="" />
          </div>
          <div className="card">
            <h3>Scrutiny Of Documents</h3>
            <div className="title">Legal Documents</div>
            <ul>
              <li>7/12 Extract / Property Card</li>
              <li>Conveyance Deed (In case of Society)</li>
              <li>Society Registration Certificate (In case of Society)</li>
              <li>Share Certificate of Each Flat (In case of Society)</li>
              <li>Deed Of Declaration (In case of Apartment)</li>
              <li>Deed of Apartment of Each Flat (In case of Apartment)</li>
              <li>Index II of each flat</li>
            </ul>
            <div className="titleColor">Other Documents</div>
            <ul>
              <li>Sanctioned Layout of existing building</li>
              <li>Occupation Certificate</li>
              <li>Latest Audit Report</li>
              <li>Structural Audit Report</li>
              <li>Demarcation</li>
              <li>Resolution of GB Meeting</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="close" onClick={() => setServicePopup6(false)}>
        <IoCloseCircleOutline className="icon" />
      </div>
    </ServicePopup>
  );
};

export default ServicePopup6;
