import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
// import Hero from "../components/Hero";
// import ThreeCard from "../components/ThreeCard";
// import AboutCompo from "../components/AboutCompo";
// import Project from "../components/Project";
import ServicesCompo from "../components/ServicesCompo";
import BlogNews from "../components/BlogNews";
// import ContatCompo from "../components/ContatCompo";
import SliderCompo from "../components/SliderCompo";
import Process from "../components/Process";
import HomeBanner from "../components/HomeBanner";
import Allied from "../components/Allied";
import { useAPI } from "../context/getContext";
import Navbar from "../components/Navbar";

const Home = ({
  setPopup,
  setPopup2,
  setPopup3,
  setServicePopup1,
  setServicePopup2,
  setServicePopup3,
  setServicePopup6,
  toggleNav,
}) => {
  const { contactData } = useAPI();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>RTH Redevelopment</title>
        <link rel="icon" id="faviconIcon" href={contactData.favicon} />
      </Helmet>
      <Navbar
        link1="link"
        link2="link link--metis"
        link3="link link--metis"
        link4="link link--metis"
        link5="link link--metis"
        link6="link link--metis"
      />
      <HomeBanner />
      {/* <Hero /> */}
      {/* <ThreeCard /> */}
      {/* <AboutCompo /> */}
      <Process />
      <ServicesCompo
        setServicePopup1={setServicePopup1}
        setServicePopup2={setServicePopup2}
        setServicePopup3={setServicePopup3}
        setServicePopup6={setServicePopup6}
        toggleNav={toggleNav}
      />
      <SliderCompo toggleNav={toggleNav} />
      {/* <Project /> */}
      <BlogNews
        setPopup={setPopup}
        setPopup2={setPopup2}
        setPopup3={setPopup3}
        toggleNav={toggleNav}
      />
      <Allied />
    </div>
  );
};

export default Home;
