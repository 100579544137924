import React from "react";
import { ServicePopup } from "../../Utils/CompoStyles";
import { construction } from "../../Utils/Images";
import { IoCloseCircleOutline } from "react-icons/io5";
import { ImArrowRight } from "react-icons/im";

const ServicesPopup3 = ({ setServicePopup3 }) => {
  return (
    <ServicePopup>
      <div className="overlay"></div>
      <div className="data">
        <div className="grid">
          <div className="card">
            <img src={construction} alt="" />
          </div>
          <div className="card">
            <h3>Construction Management</h3>
            <ul>
              <li>Periodic site visits to supervise the construction activity</li>
              <li>Review meetings with society members and developers</li>
              <li>Guiding the Developer with standard construction practices</li>
              <li>Finalising finishing materials</li>
              <li>Checking all test reports</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="close" onClick={() => setServicePopup3(false)}>
        <IoCloseCircleOutline className="icon" />
      </div>
    </ServicePopup>
  );
};

export default ServicesPopup3;
