import React, { useEffect, useState } from "react";
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import { APIContextProvider } from "./context/getContext";
import "./App.css";
import { Helmet } from "react-helmet";
import Navbar from "./components/Navbar";
import MobileMenu from "./components/MobileMenu";
import Home from "./pages/Home";
import Footer from "./components/Footer";
import About from "./pages/About";
import Services from "./pages/Services";
import Board from "./pages/Board";
import Blog from "./pages/Blog";
import Contact from "./pages/Contact";
import Enquiry from "./pages/Enquiry";
import Popup from "./components/Popup";
import ServicesPopup1 from "./components/ServicesPopup/ServicesPopup1";
import ServicesPopup2 from "./components/ServicesPopup/ServicesPopup2";
import ServicesPopup3 from "./components/ServicesPopup/ServicesPopup3";
import StickySocial from "./components/StickySocial";
import ServicesPopup4 from "./components/ServicesPopup/ServicesPopup4";
import ServicesPopup5 from "./components/ServicesPopup/ServicesPopup5";
import ServicePopup6 from "./components/ServicesPopup/ServicePopup6";
import Popup2 from "./components/BlogNews/Popup2";
import Popup3 from "./components/BlogNews/Popup3";
import Popup4 from "./components/BlogNews/Popup4";
import Terms from "./pages/Terms";
import Privacy from "./pages/Privacy";
import ScrollTop from "./components/ScrollTop";
import { ToastContainer } from "react-toastify";

function App() {
  const [popup, setPopup] = useState(false);
  const [popup2, setPopup2] = useState(false);
  const [popup3, setPopup3] = useState(false);
  const [servicePopup1, setServicePopup1] = useState(false);
  const [servicePopup2, setServicePopup2] = useState(false);
  const [servicePopup3, setServicePopup3] = useState(false);
  const [servicePopup4, setServicePopup4] = useState(false);
  const [servicePopup5, setServicePopup5] = useState(false);
  const [servicePopup6, setServicePopup6] = useState(false);

  const [navToggle, setNavToggle] = useState(1);
  const toggleNav = (index) => {
    setNavToggle(index);
  };

  // Base URL : https://rthredevelopment.com/admin
  // applex@gmail.com Applex@@2021

  const [contactData, setContactData] = useState([]);

  const getContactData = async () => {
    return await fetch(
      "https://rthredevelopment.com/admin/api/GetContactDetails"
    )
      .then((res) => res.json())
      .then((res) => {
        console.log(res.data);
        setContactData(res.data);
      });
  };

  useEffect(() => {
    getContactData();
  }, []);

  return (
    <APIContextProvider>
      <Helmet>
        <meta charSet="utf-8" />
        <title>RTH Redevelopment</title>
        <link rel="icon" id="faviconIcon" href={contactData.favicon} />
      </Helmet>
      <Router>
        {/* <TopHeader /> */}
        {/* <Navbar navToggle={navToggle} toggleNav={toggleNav} /> */}
        <MobileMenu />
        <StickySocial />
        <Routes>
          <Route
            path="/"
            element={
              <Home
                toggleNav={toggleNav}
                setPopup={setPopup}
                setPopup2={setPopup2}
                setPopup3={setPopup3}
                setServicePopup1={setServicePopup1}
                setServicePopup2={setServicePopup2}
                setServicePopup3={setServicePopup3}
                setServicePopup6={setServicePopup6}
              />
            }
          />
          <Route path="/about-us" element={<About />} />
          <Route
            path="/services"
            element={
              <Services
                setServicePopup1={setServicePopup1}
                setServicePopup2={setServicePopup2}
                setServicePopup3={setServicePopup3}
                setServicePopup4={setServicePopup4}
                setServicePopup5={setServicePopup5}
                setServicePopup6={setServicePopup6}
              />
            }
          />
          <Route path="/builders-on-board" element={<Board />} />
          <Route
            path="/blog"
            element={
              <Blog
                setPopup={setPopup}
                setPopup2={setPopup2}
                setPopup3={setPopup3}
              />
            }
          />
          <Route path="/contact" element={<Contact />} />
          <Route path="/enquiry-for-consultancy" element={<Enquiry />} />
          <Route path="/terms-condition" element={<Terms />} />
          <Route path="/privacy-policy" element={<Privacy />} />
        </Routes>
        <Footer />
        {popup ? <Popup setPopup={setPopup} /> : null}
        {popup2 ? <Popup2 setPopup2={setPopup2} /> : null}
        {popup3 ? <Popup3 setPopup3={setPopup3} /> : null}
        {servicePopup1 ? (
          <ServicesPopup1 setServicePopup1={setServicePopup1} />
        ) : null}
        {servicePopup2 ? (
          <ServicesPopup2 setServicePopup2={setServicePopup2} />
        ) : null}
        {servicePopup3 ? (
          <ServicesPopup3 setServicePopup3={setServicePopup3} />
        ) : null}
        {servicePopup4 ? (
          <ServicesPopup4 setServicePopup4={setServicePopup4} />
        ) : null}
        {servicePopup5 ? (
          <ServicesPopup5 setServicePopup5={setServicePopup5} />
        ) : null}
        {servicePopup6 ? (
          <ServicePopup6 setServicePopup6={setServicePopup6} />
        ) : null}
        <ScrollTop />
      </Router>
      <ToastContainer className="toast-position" />
    </APIContextProvider>
  );
}

export default App;
