import React, { useContext, useState, useEffect, createContext } from "react";

const APIContext = createContext();

export function APIContextProvider({ children }) {
  const [contactData, setContactData] = useState([]);
  const [workingData, setWorkingData] = useState([]);
  const [termsData, setTermsData] = useState([]);
  const [policyData, setPolicyData] = useState([]);
  const [teamsData, setTeamsData] = useState([]);
  const [aboutData, setAboutData] = useState([]);
  const [associateData, setAssociateData] = useState([]);
  const [alliedData, setAlliedData] = useState([]);
  const [buildersData, setBuildersData] = useState([]);
  const [servicesData, setServicesData] = useState([]);
  const [vlogsData, setVlogsData] = useState([]);
  const [captchaData, setCaptchaData] = useState([]);

  const getCaptchaData = async () => {
    return await fetch("https://rthredevelopment.com/admin/api/GetContactDetails")
      .then((res) => res.json())
      .then((res) => {
        setCaptchaData(res.data.recaptcha_detail);
      });
  };

  const getContactData = async () => {
    return await fetch("https://rthredevelopment.com/admin/api/GetContactDetails")
      .then((res) => res.json())
      .then((res) => {
        setContactData(res.data);
      });
  };

  const getWorkingData = async () => {
    return await fetch("https://rthredevelopment.com/admin/api/GetWorkingProcess")
      .then((res) => res.json())
      .then((res) => {
        setWorkingData(res.data);
      });
  };

  const getTermsData = async () => {
    return await fetch("https://rthredevelopment.com/admin/api/GetTermsConditions")
      .then((res) => res.json())
      .then((res) => {
        setTermsData(res.data);
      });
  };

  const getPolicyData = async () => {
    return await fetch("https://rthredevelopment.com/admin/api/GetPrivacyPolicy")
      .then((res) => res.json())
      .then((res) => {
        setPolicyData(res.data);
      });
  };

  const getTeamsData = async () => {
    return await fetch("https://rthredevelopment.com/admin/api/GetOurTeam")
      .then((res) => res.json())
      .then((res) => {
        setTeamsData(res.data);
      });
  };

  const getAboutData = async () => {
    return await fetch("https://rthredevelopment.com/admin/api/GetAboutDetails")
      .then((res) => res.json())
      .then((res) => {
        setAboutData(res.data);
      });
  };

  const getAssociateData = async () => {
    return await fetch("https://rthredevelopment.com/admin/api/GetOurAssociates")
      .then((res) => res.json())
      .then((res) => {
        setAssociateData(res.data);
      });
  };

  const getAlliedData = async () => {
    return await fetch("https://rthredevelopment.com/admin/api/GetAlliedServices")
      .then((res) => res.json())
      .then((res) => {
        setAlliedData(res.data);
      });
  };

  const getBuildersData = async () => {
    return await fetch("https://rthredevelopment.com/admin/api/GetBuildersOnBoard")
      .then((res) => res.json())
      .then((res) => {
        setBuildersData(res.data);
      });
  };

  const getServicesData = async () => {
    return await fetch("https://rthredevelopment.com/admin/api/GetServices")
      .then((res) => res.json())
      .then((res) => {
        setServicesData(res.data);
      });
  };

  const getVlogsData = async () => {
    return await fetch("https://rthredevelopment.com/admin/api/GetBlogs")
      .then((res) => res.json())
      .then((res) => {
        setVlogsData(res.data);
      });
  };

  useEffect(() => {
    getContactData();
    getWorkingData();
    getTermsData();
    getPolicyData();
    getTeamsData();
    getAboutData();
    getAssociateData();
    getAlliedData();
    getBuildersData();
    getServicesData();
    getVlogsData();
    getCaptchaData();
  }, []);

  return (
    <APIContext.Provider
      value={{
        contactData,
        workingData,
        termsData,
        policyData,
        teamsData,
        aboutData,
        associateData,
        alliedData,
        buildersData,
        servicesData,
        vlogsData,
        captchaData,
      }}
    >
      {children}
    </APIContext.Provider>
  );
}

export function useAPI() {
  const context = useContext(APIContext);
  if (context === undefined) {
    throw new Error("Context must be used within a Provider");
  }
  return context;
}
