import React from "react";
import styled from "styled-components";
import { IoCloseCircleOutline } from "react-icons/io5";

const ServicePopup = (props) => {
  return props.trigger ? (
    <VlogMain>
      <div className="modalVideo">
        <div className="modalOverlay"></div>
        <div className="data">{props.children}</div>
        <div className="close" onClick={() => props.setTrigger(false)}>
          <IoCloseCircleOutline className="icon" />
        </div>
      </div>
    </VlogMain>
  ) : (
    ""
  );
};

export default ServicePopup;

const VlogMain = styled.div`
  .modalVideo {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000000;
    padding: 8rem;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  .modalOverlay {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #000;
    opacity: 0.7;
    height: 100%;
    width: 100%;
    z-index: -1;
    overflow: hidden;
  }
  .data {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 1000px;
    height: 570px;
    background-color: #fff;
    z-index: 100008;
    overflow-y: scroll;
    overflow-x: hidden;

    .grid {
      display: grid;
      grid-template-columns: 0.8fr 1fr;
      grid-gap: 0rem;
      padding-top: 18px;

      .card {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        text-align: left;
        img {
          height: 535px;
          width: 100%;
          object-fit: cover;
        }
        &:nth-child(7) {
          img {
            display: none;
          }
        }
        h3 {
          color: #0e1e36;
          font-size: 25px;
          font-weight: 500;
          margin-bottom: 0.7rem;
          text-align: left;
        }
        div {
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
          text-align: left;
        }
        ul {
          list-style: disc !important;
          li {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            font-size: 15px;
            font-weight: 500;
            margin-bottom: 0.4rem;

            .icon {
              font-size: 30px;
            }
          }
        }
      }
    }
    @media only screen and (max-width: 991px) {
      width: 98%;
      height: 570px;
      margin-top: 1.7rem;
      .grid {
        grid-template-columns: 1fr;
        padding-top: 0px;
        padding-bottom: 15px;

        .card {
          img {
            height: 350px;
            width: 100%;
          }
        }
      }
    }
  }
  .close {
    position: fixed;
    top: 3%;
    right: 5%;
    z-index: 100008;
    cursor: pointer;

    .icon {
      color: #fff;
      font-size: 45px;
    }
    @media only screen and (max-width: 991px) {
      top: 1.5%;
      right: 5%;
    }
  }
`;
