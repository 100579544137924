import React from "react";
import styled from "styled-components";
import { MdKeyboardDoubleArrowRight } from "../Utils/Icons";
import { Link } from "react-router-dom";
import { useAPI } from "../context/getContext";
// import { rthLogo1 } from "../Utils/Images";

const Navbar = (props) => {
  const { contactData } = useAPI();

  return (
    <NavbarMain className="space-between">
      <div>
        <Link to="/">
          <img src={contactData.logo} alt="" />
        </Link>
      </div>
      <div className="flex-center" style={{ marginRight: "-5.5rem" }}>
        <ul className="flex-center">
          <li>
            <Link to="/" className={props.link1}>
              Home
            </Link>
          </li>
          <li>
            <Link to="/about-us" className={props.link2}>
              About Us
            </Link>
          </li>
          <li>
            <Link to="/services" className={props.link3}>
              Services
            </Link>
          </li>
          <li>
            <Link to="/builders-on-board" className={props.link4}>
              Builders On Board
            </Link>
          </li>
          <li>
            <Link to="/blog" className={props.link5}>
              Vlogs
            </Link>
          </li>
          <li>
            <Link to="/contact" className={props.link6}>
              Contact Us
            </Link>
          </li>
        </ul>
        <Link to="/enquiry-for-consultancy">
          <div
            className="button flex-center"
          >
            Enquiry For Redevelopment
            <MdKeyboardDoubleArrowRight className="iconRight" />
            <div className={props.link7}></div>
          </div>
        </Link>
      </div>
      <div></div>
    </NavbarMain>
  );
};

export default Navbar;

const NavbarMain = styled.div`
  position: sticky;
  top: 0%;
  width: 100%;
  z-index: 100000;
  background-color: #fff;
  padding: 0px 20px;
  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.16));

  div {
    img {
      width: 320px;
      height: 80px;
    }
    ul {
      display: flex;
      align-items: center;
      justify-content: center;
      li {
        margin-right: 20px;
        a {
          color: #04213f;
          font-weight: 500;
          font-size: 16px;
        }
      }
    }
    .button {
      position: relative;
      padding: 16px 13px;
      background-color: var(--primary-color);
      color: #fff;
      font-size: 16px;
      font-weight: 500;
      text-align: center;
      border-radius: 10px;

      .iconRight {
        font-size: 22px;
        margin-bottom: -3px;
        margin-left: 5px;
      }
      .border {
        position: absolute;
        top: 73%;
        left: 4.5%;
        background-color: #fff;
        height: 2px;
        width: 90%;
      }
    }
    .activeBtn {
      padding: 16px 13px;
      background-color: var(--primary-color);
      color: #fff;
      font-size: 16px;
      font-weight: 500;
      text-align: center;
      border-radius: 10px;
      border-bottom: 2px solid #fff;

      .iconRight {
        font-size: 22px;
        margin-bottom: -3px;
        margin-left: 5px;
      }
    }
  }
  @media only screen and (max-width: 991px) {
    display: none;
  }
`;
