import React from "react";
import { ServicePopup } from "../../Utils/CompoStyles";
import { report } from "../../Utils/Images";
import { IoCloseCircleOutline } from "react-icons/io5";

const ServicesPopup1 = ({ setServicePopup1 }) => {
  return (
    <ServicePopup>
      <div className="overlay"></div>
      <div className="data">
        <div className="grid">
          <div className="card">
            <img src={report} alt="" />
          </div>
          <div className="card">
            <h3>Feasibility Report</h3>
            <ul>
              <li>Finalizing Carpet Area of all the flats</li>
              <li> Verifying and finalizing Plot Area</li>
              <li>Checking DP Remarks</li>
              <li>Checking Ready Reckoner Rates</li>
              <li>Preparing Block Plan to check the Construction Potential</li>
              <li>Calculating the Project Cost</li>
              <li>
                Calculating area available for Developer for sale to make the
                project profitable for him
              </li>
              <li>
                Calculating balance carpet area which can be allotted free to
                exiting members
              </li>
              <li>Checking overall Viability of the project</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="close" onClick={() => setServicePopup1(false)}>
        <IoCloseCircleOutline className="icon" />
      </div>
    </ServicePopup>
  );
};

export default ServicesPopup1;
