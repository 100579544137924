import React from "react";
import { IoCloseCircleOutline } from "react-icons/io5";
import { PopupMain } from "../../Utils/CompoStyles";

const Popup2 = ({ setPopup2 }) => {
  return (
    <PopupMain>
      <div className="overlay"></div>
      <div className="data">
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/HraXERyBd0c?si=LjHUSpcHzv25_2tw"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
      </div>
      <div className="close" onClick={() => setPopup2(false)}>
        <IoCloseCircleOutline className="icon" />
      </div>
    </PopupMain>
  );
};

export default Popup2;
