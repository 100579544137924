import React, { useState } from "react";
import styled from "styled-components";
import { blogBg, bage2, blog1 } from "../Utils/Images";
import { MdKeyboardDoubleArrowRight } from "../Utils/Icons";
import { FaPlay } from "react-icons/fa";
import { SlCalender } from "react-icons/sl";
import { Link } from "react-router-dom";
import { useAPI } from "../context/getContext";
import VlogPopup from "./VlogPopup";

const BlogNews = ({ setPopup, setPopup2, setPopup3, toggleNav }) => {
  const { vlogsData } = useAPI();
  const [ButtonPopup, setButtonPopup] = useState(false);
  return (
    <>
      <BlogContainer>
        <img src={blogBg} alt="" className="bgService" />
        <div className="labelMain">
          <img src={bage2} alt="" />
          <h4>Vlogs</h4>
        </div>
        <h2>Vlogs on Redevelopment process and Project Reviews</h2>
        <BlogGrid>
          {/* <img src={canOverlayHome} alt="" className="overlay" /> */}
          <div className="grid">
            {vlogsData.slice(0, 3).map((item, index) => {
              return (
                <div key={index}>
                  <div className="card">
                    <div className="imgContain">
                      <iframe
                        width="560"
                        height="315"
                        src={item.video}
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowfullscreen
                      ></iframe>
                      <div className="imgOverlay"></div>
                      <div
                        className="playBtn"
                        onClick={() => setButtonPopup(index)}
                      >
                        <FaPlay className="icon" />
                      </div>
                    </div>
                    <p>
                      <SlCalender className="icoCalender" />
                      {item.date}
                    </p>
                    <h4>{item.title}</h4>
                  </div>
                  <VlogPopup
                    trigger={ButtonPopup === index}
                    setTrigger={setButtonPopup}
                  >
                    <iframe
                      width="560"
                      height="315"
                      src={item.video}
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowfullscreen
                    ></iframe>
                  </VlogPopup>
                </div>
              );
            })}
            {/* <div className="card">
              <div className="imgContain">
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/HraXERyBd0c?si=LjHUSpcHzv25_2tw"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
                <div className="imgOverlay"></div>{" "}
                <div className="playBtn" onClick={() => setPopup2(true)}>
                  <FaPlay className="icon" />
                </div>
              </div>
              <p>
                <SlCalender className="icoCalender" />
                25/ 12/ 2023
              </p>
              <h4>How to get Redevelopment Approval from Society in Marathi</h4>
            </div>
            <div className="card">
              <div className="imgContain">
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/0V2Ealb4IWA?si=Vlp2D0Pb2EZ8RKX4"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
                <div className="imgOverlay"></div>{" "}
                <div className="playBtn" onClick={() => setPopup3(true)}>
                  <FaPlay className="icon" />
                </div>
              </div>
              <p>
                <SlCalender className="icoCalender" />
                25/ 12/ 2023
              </p>
              <h4>When to think about Redevelopment in Marathi</h4>
            </div> */}
          </div>
        </BlogGrid>
        <div className="flex-center" onClick={() => toggleNav(5)}>
          <Link to="/blog" className="theme_btn btn-primary flex-center">
            Read More
            <MdKeyboardDoubleArrowRight className="iconRight" />
          </Link>
        </div>
      </BlogContainer>

      {/* <div className="icons">
                  <div className="icoContainer">
                    <SlCalender className="icon" /> <span>30 SEPT 2022</span>
                  </div>
                  <div className="icoContainer">
                    <BsPerson className="icon" /> <span>by Admin</span>
                  </div>
                  <div className="icoContainer">
                    <LiaComments className="icon" /> <span>16 comments</span>
                  </div>
                  <div className="icoContainer">
                    <MdLabel className="icon" />
                    <span>
                      Business, <br /> Consultant
                    </span>
                  </div>
                </div> */}
    </>
  );
};

export default BlogNews;

const BlogContainer = styled.div`
  overflow: hidden;
  position: relative;

  .bgService {
    position: absolute;
    top: 0%;
    z-index: -1;
    height: 500px;
    width: 100%;
    object-fit: cover;
  }
  .labelMain {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 30px;

    h4 {
      color: var(--primary-color);
      font-weight: 500;
      font-size: 19px;
      margin-left: 15px;
    }
  }
  h2 {
    text-align: center;
    font-size: 2rem;
    font-weight: 600;
    color: #fff;
    padding-bottom: 140px;
  }
  @media only screen and (max-width: 991px) {
    h2 {
      font-size: 2rem;
      line-height: 35px;
    }
  }
`;

const BlogGrid = styled.div`
  margin-top: -5rem;
  margin-bottom: 0rem;

  .overlay {
    position: absolute;
    top: 30%;
    left: -5%;
    z-index: 100000;
    width: 100%;
    height: 800px;
    overflow: hidden;
  }
  .grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 2rem;
    margin: 0px 0.7rem;

    .card {
      background-color: #fff;
      padding: 20px;
      -webkit-box-shadow: 0px 2px 32px rgba(14, 10, 1, 0.05);
      box-shadow: 0px 2px 32px rgba(14, 10, 1, 0.05);
      .imgContain {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        position: relative;

        .imgOverlay {
          position: absolute;
          top: 0%;
          left: 0%;
          width: 100%;
          height: 300px;
          background-color: rgba(0, 0, 0, 0.4);
        }
        .playBtn {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 50px;
          height: 50px;
          border: 2px solid #fff;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          z-index: 1000;

          .icon {
            color: #fff;
          }
        }
        iframe {
          width: 100%;
          height: 300px;
        }

        .icons {
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
          flex-direction: column;
          margin-left: 20px;

          .icoContainer {
            .icon {
              color: #1378be;
              font-size: 22px;
            }
            span {
              margin-left: 7px;
              font-size: 16px;
              color: #243342;
              text-transform: uppercase;
            }
          }
        }
      }
      h4 {
        font-style: normal;
        font-weight: 600;
        font-size: 22px;
        line-height: 30px;
        text-transform: capitalize;
        color: #0e0a01;
        margin-bottom: 10px;
        width: 100%;
      }
      p {
        display: flex;
        align-items: center;
        justify-content: start;
        margin-top: 1.8rem;
        color: #243342;
        margin-bottom: 0.5rem;
        font-size: 15px;
        font-weight: 500;

        .icoCalender {
          margin-right: 8px;
        }
      }
      .readMore {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        border: 1px solid #1378be;
        padding: 12px 30px;
        width: 50%;
        border-radius: 3px;
        color: #1378be;
        cursor: pointer;
        .icon {
          color: #1378be;
          margin-left: 8px;
        }
      }
    }
  }
  @media only screen and (max-width: 991px) {
    .grid {
      grid-template-columns: 1fr;

      .card {
        .readMore {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          border: 1px solid #1378be;
          padding: 12px 30px;
          width: 60%;
          border-radius: 3px;
          color: #1378be;
          cursor: pointer;
          .icon {
            color: #1378be;
            margin-left: 8px;
          }
        }
      }
    }
  }
`;
