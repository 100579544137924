import styled from "styled-components";

export const ServicePopup = styled.div`
  .overlay {
    position: fixed;
    top: 0%;
    left: 0%;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 100005;
  }
  .close {
    position: fixed;
    top: 5%;
    right: 7%;
    z-index: 100008;
    cursor: pointer;

    .icon {
      color: #fff;
      font-size: 45px;
    }
    @media only screen and (max-width: 991px) {
      top: 1%;
      right: 1%;
    }
  }
  .dataCard {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 900px;
    height: 570px;
    background-color: #fff;
    z-index: 100008;
    overflow-y: scroll;

    .iconArrow1 {
      position: absolute;
      top: 15%;
      left: 31%;
      color: #da1f28;
      font-size: 30px;
    }
    .iconArrow2 {
      position: absolute;
      top: 15%;
      left: 63.5%;
      color: #eb641b;
      font-size: 30px;
    }
    h3 {
      color: #0e1e36;
      font-size: 27px;
      font-weight: 500;
      margin-bottom: 0rem;
      padding: 15px;
    }
    .gridCol3 {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 1rem;
      padding: 15px;
      margin-top: -1rem;

      .card {
        padding: 15px;
        background-color: #eee;

        &:nth-child(1) {
          border: 2px solid #da1f28;
        }
        &:nth-child(2) {
          border: 2px solid #eb641b;
        }
        &:nth-child(3) {
          border: 2px solid #39639d;
        }
        h4 {
          display: none;
        }
        ul {
          li {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            font-size: 15px;
            font-weight: 500;
            margin-bottom: 0.6rem;
          }
        }
      }
      @media only screen and (max-width: 991px) {
        grid-template-columns: 1fr;

        .card {
          h4 {
            display: block;
            color: #0e1e36;
            font-size: 20px;
            font-weight: 600;
            margin-bottom: 0.5rem;
          }
        }
      }
    }
    .spaceBetween {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 15px;
      margin-top: -1.2rem;
      position: relative;

      .card {
        padding: 25px;
        color: #fff;
        font-size: 17px;
        font-weight: 500;
        border-radius: 5px;
        &:nth-child(1) {
          background-color: #da1f28;
        }
        &:nth-child(2) {
          background-color: #eb641b;
        }
        &:nth-child(3) {
          background-color: #39639d;
        }
      }
      @media only screen and (max-width: 991px) {
        align-items: flex-start;
        flex-wrap: wrap;
        display: none;

        .card {
          padding: 13px 5px;
          margin-bottom: 0.7rem;
        }
      }
    }
    @media only screen and (max-width: 991px) {
      width: 100%;
      height: 800px;
      margin-top: 1.7rem;

      .iconArrow1,
      .iconArrow2 {
        display: none;
      }
    }
  }
  .data {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 1000px;
    height: 550px;
    background-color: #fff;
    z-index: 100008;
    overflow-y: scroll;

    .title {
      background-color: #eb641b;
      padding: 5px 15px;
      color: #fff;
      font-size: 20px;
      font-weight: 500;
      border-radius: 5px;
      margin-bottom: 10px;
    }
    .titleColor {
      background-color: #39639d;
      padding: 5px 15px;
      color: #fff;
      font-size: 20px;
      font-weight: 500;
      border-radius: 5px;
      margin-bottom: 10px;
    }
    .iconArrow1 {
      position: absolute;
      top: 18%;
      left: 31%;
      color: #da1f28;
      font-size: 30px;
    }
    .iconArrow2 {
      position: absolute;
      top: 18%;
      left: 63.5%;
      color: #eb641b;
      font-size: 30px;
    }
    .grid {
      display: grid;
      grid-template-columns: 0.8fr 1fr;
      grid-gap: 1rem;
      padding: 15px;

      .card {
        position: relative;
        img {
          position: sticky;
          top: 3%;
          height: 515px;
          width: 100%;
          object-fit: cover;
        }
        h3 {
          color: #0e1e36;
          font-size: 27px;
          font-weight: 500;
          margin-bottom: 0.7rem;
        }
        ul {
          list-style: disc !important;
          li {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            font-size: 15px;
            font-weight: 500;
            margin-bottom: 0.4rem;

            .icon {
              font-size: 30px;
            }
          }
        }
      }
    }
    @media only screen and (max-width: 991px) {
      width: 100%;
      height: 600px;
      margin-top: 1.7rem;
      .grid {
        grid-template-columns: 1fr;

        .card {
          img {
            height: 300px;
            width: 100%;
          }
        }
      }
    }
  }
  .documentData {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 900px;
    height: 550px;
    background-color: #fff;
    z-index: 100008;
    overflow-y: scroll;
    padding: 15px;

    h3 {
      color: #0e1e36;
      font-size: 27px;
      font-weight: 500;
      margin-bottom: 0.8rem;
    }
    .title {
      background-color: #eb641b;
      padding: 5px 15px;
      color: #fff;
      font-size: 20px;
      font-weight: 500;
      border-radius: 5px;
    }
    .titleColor {
      background-color: #39639d;
      padding: 5px 15px;
      color: #fff;
      font-size: 20px;
      font-weight: 500;
      border-radius: 5px;
    }
    ul {
      list-style: disc !important;
      margin-top: 0.7rem;
      margin-bottom: 0.7rem;
      li {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-size: 15px;
        font-weight: 500;
        margin-bottom: 0.4rem;

        .icon {
          font-size: 30px;
        }
      }
    }
  }
`;

export const SliderMain = styled.div`
  position: relative;

  .bgService {
    position: absolute;
    top: 0%;
    z-index: -1;
    height: 100%;
    width: 100%;
  }
  .labelMain {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 150px;

    h4 {
      color: var(--primary-color);
      font-weight: 500;
      font-size: 19px;
      margin-left: 15px;
    }
  }
  h2 {
    text-align: center;
    font-size: 2.8rem;
    font-weight: 600;
    color: #0e1e36;
  }
  .btn {
    margin-bottom: 5rem;
  }
  @media only screen and (max-width: 991px) {
    h2 {
      font-size: 2rem;
    }
    .bgService {
      position: absolute;
      top: 0%;
      z-index: -1;
      height: 2400px;
      width: 100%;
    }
  }
`;

export const SliderGrid = styled.div`
  padding: 15px 0px;
  border-radius: 4px;
  margin: 0px 30px;
  margin-top: 4rem;
  margin-bottom: 4.5rem;

  div {
    img {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: auto;
      height: 100px;
      width: 170px;
    }
  }
`;

export const LogoGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 1rem;
  margin-top: 4rem;
  margin-bottom: 4.5rem;
  margin-left: 1rem;
  margin-right: 1rem;

  .card {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    background-color: #fff;
    -webkit-box-shadow: 0px 2px 32px rgba(14, 10, 1, 0.05);
    box-shadow: 0px 2px 32px rgba(14, 10, 1, 0.05);
    border: 1px solid #1378be;
    padding: 15px;
    height: 470px;
    width: 315px;
    img {
      position: absolute;
      top: 30%;
      left: 50%;
      transform: translate(-50%, -70%);
      display: flex;
      align-items: center;
      justify-content: center;
      margin: auto;
      width: 250px;
      max-width: 100%;
      height: auto;
    }
    h4 {
      position: absolute;
      top: 41%;
      left: 50%;
      transform: translateX(-50%);
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      margin-top: 1rem;
      line-height: 22px;
      font-size: 20px;
      color: #0e1e36;
      margin-top: 1rem;
    }
    p {
      text-align: left;
    }
    .listData {
      position: absolute;
      top: 52%;
      left: 50%;
      transform: translateX(-50%);
      width: 100%;
      padding: 15px;
      list-style: disc;
      margin-left: 1rem;
      li {
        font-size: 15px;
        font-weight: 500;
        color: #0e1e36;

        a {
          color: darkblue;
        }
      }
    }
  }
  @media only screen and (max-width: 991px) {
    grid-template-columns: 1fr;
  }
`;

export const PopupMain = styled.div`
  .overlay {
    position: fixed;
    top: 0%;
    left: 0%;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 100005;
  }
  .data {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 800px;
    height: 500px;
    background-color: #fff;
    z-index: 100008;
    overflow: hidden;

    iframe {
      width: 800px;
      height: 500px;
      padding: 15px;
    }
  }
  .close {
    position: fixed;
    top: 10%;
    right: 5%;
    z-index: 100008;
    cursor: pointer;

    .icon {
      color: #fff;
      font-size: 45px;
    }
  }
  @media only screen and (max-width: 991px) {
    .data {
      width: 100%;
      height: 500px;

      iframe {
        width: 100%;
        height: 500px;
        padding: 15px;
      }
    }
  }
`;

export const TermsMain = styled.div`
  padding: 30px 40px;
  margin: 50px 120px;
  background-color: #fff;
  box-shadow: 0px 10px 30px rgba(4, 33, 63, 0.05);
  h5 {
    font-weight: 500;
    font-size: 20px;
  }
  ul {
    list-style: disc;
    margin-left: 2.5rem;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    li {
      font-size: 17px;
      font-weight: 500;
      margin-bottom: 14px;
    }
  }
`;
