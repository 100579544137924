import React from "react";
import styled from "styled-components";
import { bage, sliderBg } from "../Utils/Images";
import Allied1 from "../assets/Allied1.jpg";
import Allied2 from "../assets/Allied2.jpg";
import Allied3 from "../assets/Allied3.jpg";
import Allied4 from "../assets/Allied4.jpg";
import { useAPI } from "../context/getContext";

const Allied = () => {
  const { alliedData } = useAPI();
  return (
    <AlliedContainer>
      <img src={sliderBg} alt="" className="bgService" />
      <div>
        <div className="labelMain">
          <img src={bage} alt="" />
          <h4>Other Services</h4>
        </div>
        <h2>Allied Services</h2>
      </div>
      <AlliedMain>
        <div className="grid">
          {alliedData.map((item) => {
            return (
              <div className="card">
                <img src={item.image} alt="" />
                <h3>{item.title}</h3>
                <p>{item.description}</p>
              </div>
            );
          })}
          {/* <div className="card">
            <img src={Allied2} alt="" />
            <h3>Interior Designers</h3>
            <p>
              Need to finalise interiors of your new house? Get the help of our{" "}
              <strong>Interior Designers</strong> before the start of civil work
            </p>
          </div>
          <div className="card">
            <img src={Allied3} alt="" />
            <h3>Property Dealer</h3>
            <p>
              Helping you in finding temporary accommodation nearby during the
              redevelopment work through our partners{" "}
              <strong>‘Ghatpande Properties’</strong>
            </p>
          </div>
          <div className="card">
            <img src={Allied4} alt="" />
            <h3>Movers & Packers</h3>
            <p>
              Helping you in shifting your household goods before and after the
              redevelopment work through our partners{" "}
              <strong>‘Associated Cargo Movers & Packers’</strong>
            </p>
          </div> */}
        </div>
      </AlliedMain>
    </AlliedContainer>
  );
};

export default Allied;

const AlliedContainer = styled.div`
  position: relative;

  .bgService {
    position: absolute;
    top: 0%;
    z-index: -1;
    height: 700px;
    width: 100%;

    @media only screen and (max-width: 991px) {
      height: 1350px;
    }
  }

  .labelMain {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 50px;

    h4 {
      color: var(--primary-color);
      font-weight: 500;
      font-size: 19px;
      margin-left: 15px;
    }
  }
  h2 {
    text-align: center;
    font-size: 2.8rem;
    font-weight: 600;
    color: #0e1e36;
  }
  @media only screen and (max-width: 991px) {
    h2 {
      font-size: 2rem;
    }
  }
`;

const AlliedMain = styled.div`
  .grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 1rem;
    margin-top: 4rem;
    margin-bottom: 3rem;
    margin-left: 0.7rem;
    margin-right: 0.7rem;

    .card {
      background-color: #fff;
      -webkit-box-shadow: 0px 2px 32px rgba(14, 10, 1, 0.05);
      box-shadow: 0px 2px 32px rgba(14, 10, 1, 0.05);
      border-radius: 4px;
      border: 1px solid #1378be;

      img {
        width: 100%;
        height: 220px;
        border-radius: 4px;
        border-bottom: 1px solid rgba(19, 120, 190, 0.4);
        padding: 7px;
      }
      p {
        padding: 0px 10px;
        font-size: 15px;
        font-weight: 500;
      }
      h3 {
        padding: 0px 10px;
        font-size: 25px;
        font-weight: 500;
      }
    }
    @media only screen and (max-width: 991px) {
      grid-template-columns: 1fr;
    }
  }
`;
