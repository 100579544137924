import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import {
  bgServices,
  bage,
  card1,
  card2,
  card3,
  services1,
  services2,
  report,
  tendor,
  construction,
  documents,
} from "../Utils/Images";
import {
  MdKeyboardDoubleArrowRight,
  HiOutlineArrowNarrowRight,
} from "../Utils/Icons";
import { useAPI } from "../context/getContext";
import ServicePopup from "./ServicePopup";

const ServicesCompo = ({
  setServicePopup1,
  setServicePopup2,
  setServicePopup3,
  setServicePopup6,
  toggleNav,
}) => {
  const [ButtonPopup, setButtonPopup] = useState(false);
  const { servicesData } = useAPI();
  return (
    <ServiceContainer>
      <img src={bgServices} alt="" className="bgService" />
      <TitleHeader>
        <div>
          <div className="label">
            <img src={bage} alt="" />
            <h4>SERVICES</h4>
          </div>
          <div className="title">
            <h2>Customer Friendly</h2>
            <p>Service</p>
          </div>
        </div>
        <div className="btns" onClick={() => toggleNav(3)}>
          <Link to="/services" className="theme_btn btn-primary flex-center">
            View All Services
            <MdKeyboardDoubleArrowRight className="iconRight" />
          </Link>
        </div>
      </TitleHeader>
      <ServicesGrid className="flex-center">
        <div className="grid">
          <div>
            {servicesData.map((item, index) => {
              return (
                <div key={index}>
                  {item.id === "11" ? (
                    <>
                      <div className="card">
                        <img src={item.image} alt="" />
                        <h3>{item.title}</h3>
                        <Link
                          to="#"
                          className="btn flex-center"
                          onClick={() => setButtonPopup(index)}
                        >
                          Read Details
                          <HiOutlineArrowNarrowRight className="iconRight" />
                        </Link>
                      </div>
                      <ServicePopup
                        trigger={ButtonPopup === index}
                        setTrigger={setButtonPopup}
                      >
                        <div className="data">
                          <div className="grid">
                            <div className="card">
                              <img src={item.image} alt="" />
                            </div>
                            <div className="card">
                              <h3>{item.title}</h3>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: item.description,
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </ServicePopup>
                    </>
                  ) : null}
                </div>
              );
            })}
          </div>
          <div>
            {servicesData.map((item, index) => {
              return (
                <div key={index}>
                  {item.id === "8" ? (
                    <>
                      <div className="card">
                        <img src={item.image} alt="" />
                        <h3>{item.title}</h3>
                        <Link
                          to="#"
                          className="btn flex-center"
                          onClick={() => setButtonPopup(index)}
                        >
                          Read Details
                          <HiOutlineArrowNarrowRight className="iconRight" />
                        </Link>
                      </div>
                      <ServicePopup
                        trigger={ButtonPopup === index}
                        setTrigger={setButtonPopup}
                      >
                        <div className="data">
                          <div className="grid">
                            <div className="card">
                              <img src={item.image} alt="" />
                            </div>
                            <div className="card">
                              <h3>{item.title}</h3>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: item.description,
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </ServicePopup>
                    </>
                  ) : null}
                </div>
              );
            })}
          </div>
          <div>
            {servicesData.map((item, index) => {
              return (
                <div key={index}>
                  {item.id === "10" ? (
                    <>
                      <div className="card">
                        <img src={item.image} alt="" />
                        <h3>{item.title}</h3>
                        <Link
                          to="#"
                          className="btn flex-center"
                          onClick={() => setButtonPopup(index)}
                        >
                          Read Details
                          <HiOutlineArrowNarrowRight className="iconRight" />
                        </Link>
                      </div>
                      <ServicePopup
                        trigger={ButtonPopup === index}
                        setTrigger={setButtonPopup}
                      >
                        <div className="data">
                          <div className="grid">
                            <div className="card">
                              <img src={item.image} alt="" />
                            </div>
                            <div className="card">
                              <h3>{item.title}</h3>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: item.description,
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </ServicePopup>
                    </>
                  ) : null}
                </div>
              );
            })}
          </div>
        </div>
      </ServicesGrid>
    </ServiceContainer>
  );
};

export default ServicesCompo;

const ServiceContainer = styled.div`
  overflow: hidden;
  position: relative;
  padding-bottom: 10px;

  .bgService {
    position: absolute;
    top: 0%;
    z-index: -1;
    height: 350px;
    width: 100%;
  }
`;

const TitleHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 2rem;
  margin-bottom: 3rem;
  padding: 0px 10px;
  width: 100%;

  .title {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1.2rem;
    h2 {
      font-size: 2.8rem;
      color: #0e1e36;
    }
    p {
      font-weight: 400;
      font-size: 2.8rem;
      margin-left: 1rem;
      color: var(--primary-color);
    }
  }
  @media only screen and (max-width: 991px) {
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    /* margin-top: 12rem; */

    .title {
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: column;
      margin-bottom: 1.2rem;
      h2 {
        font-size: 2rem;
      }
      p {
        font-size: 2rem;
        margin-left: 0rem;
        margin-top: -0.5rem;
      }
    }
    .btns {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
  }
`;

const ServicesGrid = styled.div`
  margin-top: 0rem;
  .grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1.5rem;
    margin: 0px 1.5rem;
    position: relative;

    .card {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      div {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding-bottom: 15px;
      }
      img {
        position: sticky;
        top: 3%;
        width: 425px;
        height: 250px;
        border-radius: 8px;
        object-fit: cover;
      }
      h3 {
        color: #0e1e36;
        text-align: center;
        width: 90%;
        margin: 1rem 0rem;
        line-height: 30px;
        font-size: 23px;
        font-weight: 500;
      }
      p {
        margin-bottom: 1.5rem;
        color: #0e1e36;
        text-align: center;
        line-height: 25px;
      }
      .btn {
        color: #fff;
        background-color: var(--primary-color);
        padding: 10px 37.8px;
        width: 100%;
        font-size: 18px;

        .iconRight {
          margin-bottom: -2px;
          margin-left: 7px;
        }
      }
    }
    @media only screen and (max-width: 991px) {
      grid-template-columns: 1fr;
      margin: 0px 10px;

      .card {
        img {
          width: 100%;
        }
      }
    }
  }
`;
