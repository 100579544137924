import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { rthLogo1 } from "../Utils/Images";
import {
  IoMenuOutline,
  IoMdClose,
  LuPhoneCall,
  MdKeyboardDoubleArrowRight,
} from "../Utils/Icons";

const MobileMenu = () => {
  const [toggleMenu, setToggleMenu] = useState(false);

  return (
    <div>
      <MobileMain>
        <div>
          <Link to="/">
            <img src={rthLogo1} alt="" />
          </Link>
        </div>
        <div onClick={() => setToggleMenu(true)}>
          <IoMenuOutline className="icon" />
        </div>
      </MobileMain>
      {toggleMenu ? (
        <MenuContainer>
          <div className="overlay" onClick={() => setToggleMenu(false)}></div>
          <div className="sidebar">
            <div
              className="close flex-center"
              onClick={() => setToggleMenu(false)}
            >
              <IoMdClose className="icoClose" />
            </div>
            <ul className="listSocial">
              <li onClick={() => setToggleMenu(false)}>
                <Link to="/">Home</Link>
              </li>
              <li onClick={() => setToggleMenu(false)}>
                <Link to="/about-us">About Us</Link>
              </li>
              <li onClick={() => setToggleMenu(false)}>
                <Link to="/services">Services</Link>
              </li>
              <li onClick={() => setToggleMenu(false)}>
                <Link to="/builders-on-board">Builders On Board</Link>
              </li>
              <li onClick={() => setToggleMenu(false)}>
                <Link to="/blog">Blog</Link>
              </li>
              <li onClick={() => setToggleMenu(false)}>
                <Link to="/contact">Contact Us</Link>
              </li>
              <Link to="/enquiry-for-consultancy" onClick={() => setToggleMenu(false)}>
                <div className="button flex-center">
                  Enquiry For Redevelopment
                  <MdKeyboardDoubleArrowRight className="iconRight" />
                  {/* <div className="border"></div> */}
                </div>
              </Link>
            </ul>
            {/* <h4>Contact Info</h4> */}
            {/* <ul className="contactList">
              <li>
                <Link to="/">Mon – Fri: 8.00 – 18.00</Link>
              </li>
              <li>
                <a href="mailto:consulter@example.com">consulter@example.com</a>
              </li>
              <li>
                <a href="tel:+123 556 8824">+123 556 8824</a>
              </li>
            </ul> */}
          </div>
        </MenuContainer>
      ) : null}
    </div>
  );
};

export default MobileMenu;

const MobileMain = styled.div`
  display: none;
  @media only screen and (max-width: 991px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    top: 0%;
    width: 100%;
    z-index: 100000;
    background-color: #fff;
    padding: 7px 20px;
    filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.16));

    div {
      img {
        width: 230px;
        height: 70px;
      }
      .icon {
        font-size: 45px;
      }
    }
  }
`;

const MenuContainer = styled.div`
  .overlay {
    position: fixed;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 300%;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 10000;
    overflow: hidden;
  }
  .button {
    position: relative;
    padding: 16px 13px;
    background-color: rgb(4, 33, 63);
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    border-radius: 10px;
    margin-top: 1.4rem;

    .iconRight {
      font-size: 22px;
      margin-bottom: -3px;
      margin-left: 5px;
    }
  }
  .sidebar {
    position: fixed;
    top: 0%;
    right: 0%;
    width: 350px;
    height: 100%;
    overflow-y: scroll;
    background-color: var(--primary-color);
    z-index: 100005;

    .close {
      margin-top: 1.2rem;
      margin-left: 1.3rem;
      width: 40px;
      height: 40px;
      border: 2px solid #fff;
      border-radius: 50%;
      .icoClose {
        font-size: 25px;
        color: #fff;
      }
    }
    .listSocial {
      padding: 25px 20px;
      li {
        padding: 17px 0px;
        border-bottom: 1px solid #fff;
        a {
          color: #fff;
          font-size: 22px;
        }
      }
    }
    h4 {
      padding: 0px 20px;
      color: #fff;
      font-size: 30px;
      font-weight: 400;
    }
    .contactList {
      padding: 25px 20px;
      padding-top: 0px !important;
      li {
        padding: 17px 0px;
        border-bottom: 1px solid #fff;
        a {
          color: #fff;
          font-size: 22px;
        }
      }
    }
    .support {
      .iconCall {
      }
    }
  }
`;
