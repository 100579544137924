import React from "react";
import styled from "styled-components";
import {
  team1,
  team2,
  team3,
  bgTeam,
  bage,
  team4,
  team5,
} from "../Utils/Images";
import { useAPI } from "../context/getContext";

const TeamSection = () => {
  const { teamsData, associateData } = useAPI();

  return (
    <TeamContainer>
      <img src={bgTeam} alt="" className="bgService" />
      <div>
        <div className="labelMain">
          <img src={bage} alt="" />
          <h4>Our Team</h4>
        </div>
        <h2>Our Team Of Architects</h2>
      </div>
      <TeamMain className="flex-center">
        <div className="grid">
          {teamsData.map((item) => {
            return (
              <div className="card flex-center">
                <img src={item.image} alt="" />
                <div className="data flex-center flex-col">
                  <h3>{item.name}</h3>
                  <p>{item.description}</p>
                </div>
              </div>
            );
          })}
          {/* <div className="card flex-center">
            <img src={team2} alt="" />
            <div className="data flex-center flex-col">
              <h3>Mr. Shirish Kurhade (B.Arch.)</h3>
              <p>Total experience of 25+ Years</p>
            </div>
          </div>
          <div className="card flex-center">
            <img src={team3} alt="" />
            <div className="data flex-center flex-col">
              <h3>Mrs. Shamika Kulkarni (B.Arch.)</h3>
              <p>Total experience of 20+ years</p>
            </div>
          </div> */}
        </div>
      </TeamMain>
      <h2>Our Team Of Professional Associates</h2>
      <TeamMain className="flex-center">
        <div className="grid">
          {associateData.map((item) => {
            return (
              <div className="card flex-center">
                <img src={item.image} alt="" />
                <div className="data flex-center flex-col">
                  <h3>{item.name}</h3>
                  <p>{item.description}</p>
                </div>
              </div>
            );
          })}
          {/* <div className="card flex-center">
            <img src={team4} alt="" />
            <div className="data flex-center flex-col">
              <h3>Mr. Amit Shahane(B.Com.FCMA)</h3>
              <p>Total experience of 15+ Years</p>
            </div>
          </div>
          <div className="card flex-center">
            <img src={team5} alt="" />
            <div className="data flex-center flex-col">
              <h3>Mrs. Avani Hardikar (MA., LLB, LLM)</h3>
              <p>Total experience of 10+ years</p>
            </div>
          </div> */}
        </div>
      </TeamMain>
    </TeamContainer>
  );
};

export default TeamSection;

const TeamContainer = styled.div`
  position: relative;

  .bgService {
    position: absolute;
    top: 0%;
    z-index: -1;
    height: 100%;
    width: 100%;
    background-color: #eff0f6;
  }
  .labelMain {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 100px;

    h4 {
      color: var(--primary-color);
      font-weight: 500;
      font-size: 19px;
      margin-left: 15px;
    }
  }
  h2 {
    text-align: center;
    font-size: 2.8rem;
    font-weight: 600;
    color: #0e1e36;
  }
`;

const TeamMain = styled.div`
  margin: 0rem 1rem;
  padding-top: 60px;
  padding-bottom: 100px;
  z-index: 1000;

  .grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 2rem;

    @media only screen and (max-width: 991px) {
      grid-template-columns: 1fr;
    }
  }
  .card {
    position: relative;
    width: 100%;
    height: 100%;
    border-top: 8px solid #1378be;
    img {
      width: 94%;
      height: 350px;
    }
    .data {
      position: absolute;
      bottom: 0%;
      left: 0%;
      background-color: #0e1e36;
      padding: 15px 20px;
      width: 100%;
      color: #fff;

      h3 {
        font-size: 19px;
        font-weight: 500;
      }
      p {
        font-size: 15px;
      }
    }
  }
`;
