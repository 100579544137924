import React from "react";
import styled from "styled-components";
import { IoCloseCircleOutline } from "react-icons/io5";

const VlogPopup = (props) => {
  return props.trigger ? (
    <VlogMain>
      <div className="modalVideo">
        <div className="modalOverlay"></div>
        <div className="center_modal">{props.children}</div>
        <div className="close" onClick={() => props.setTrigger(false)}>
          <IoCloseCircleOutline className="icon" />
        </div>
      </div>
    </VlogMain>
  ) : (
    ""
  );
};

export default VlogPopup;

const VlogMain = styled.div`
  .modalVideo {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000000;
    padding: 8rem;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  .center_modal {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    height: 525px;
    width: 825px;
    overflow: hidden;
  }
  .center_modal iframe {
    height: 525px;
    width: 825px;
  }
  @media only screen and (max-width: 991px) {
    .paddingBot {
      padding-bottom: 255px;
    }
    .center_modal,
    .center_modal iframe {
      height: 350px;
      width: 350px;
    }
  }
  @media only screen and (max-width: 414px) {
    .center_modal,
    .center_modal iframe {
      height: 360px;
      width: 380px;
    }
  }
  @media only screen and (max-width: 375px) {
    .center_modal,
    .center_modal iframe {
      height: 350px;
      width: 350px;
    }
  }
  .modalOverlay {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #000;
    opacity: 0.7;
    height: 100%;
    width: 100%;
    z-index: -1;
    overflow: hidden;
  }
  .close {
    position: fixed;
    top: 7%;
    right: 5%;
    z-index: 100008;
    cursor: pointer;

    .icon {
      color: #fff;
      font-size: 45px;
    }
  }
`;
