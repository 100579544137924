import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import TeamSection from "../components/TeamSection";
import styled from "styled-components";
import { bage, spot1, founder } from "../Utils/Images";
import BannerCompo from "../components/BannerCompo";
import { useAPI } from "../context/getContext";
import Navbar from "../components/Navbar";

const About = () => {
  const { aboutData, contactData } = useAPI();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>RTH Redevelopment - About Us</title>
        <link rel="icon" id="faviconIcon" href={contactData.favicon} />
      </Helmet>
      <Navbar
        link1="link link--metis"
        link2="link"
        link3="link link--metis"
        link4="link link--metis"
        link5="link link--metis"
        link6="link link--metis"
      />
      <BannerCompo
        title="About Us"
        titleName="About Us"
        headline="Know Our About"
        subhead="Company"
        // subhead1=""
        img="https://images.unsplash.com/photo-1582883545851-725a3b9502ce?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=870&q=80"
      />
      <AboutMain>
        <div className="grid">
          <div className="card">
            <img src={spot1} alt="" className="spot" />
            <div className="label">
              <img src={bage} alt="" />
              <h4>ABOUT US</h4>
            </div>
            <div
              dangerouslySetInnerHTML={{
                __html: aboutData.abt_desc,
              }}
            />
            <div
              dangerouslySetInnerHTML={{
                __html: aboutData.abt_desc1,
              }}
            />
            {/* <p>
              Until a few years back, the process of redevelopment was primarily
              handled by a small group of enthusiastic residential society
              members and appointed developers. However, these projects faced
              challenges as the members had limited technical and legal
              expertise. Consequently, developers gained more benefits from such
              situations than the residential society members.
            </p>
            <p>
              As a response to this, the governmental authorities mandated
              appointment of a project management or architectural consultant
              for the redevelopment of residential societies. This also
              guarantees a smoother redevelopment process and ensuring maximum
              advantages to the residential societies.
            </p>
            <p>
              <strong>RTH Redevelopment</strong>, incorporated in the year 2023,
              holds a vision to provide <strong>R</strong>apid,{" "}
              <strong>T</strong>ransparent and <strong>H</strong>asslefree
              solution for your dream redevelopment.
            </p>
            <p>
              Although our company establishment is relatively young, each of
              our team member comes with vast knowledge and experience in
              architectural planning, byelaws, project management and building
              construction. Also, we have association with professionals from
              other fields including legal, finance, liasoning, etc. required
              for the process of redevelopment which allows us to offer you
              single-window solution.
            </p> */}
          </div>
          <div className="card">
            <div className="imgMain">
              <img src={aboutData.abt_img} alt="" className="aboutImg" />
              <div className="border"></div>
              <div className="data flex-center flex-col">
                <h2>{aboutData.title}</h2>
                <h3>{aboutData.subtitle}</h3>
                {/* <p>
                  Has completed a degree in architecture From L. S. Raheja
                  School of Architecture, in the year 1999 and has 20+ years
                  experience in the field of architecture, construction and
                  project management.
                </p> */}
              </div>
            </div>
          </div>
        </div>
      </AboutMain>
      <TeamSection />
    </div>
  );
};

export default About;

const AboutMain = styled.div`
  padding-top: 50px;
  padding-bottom: 0px;
  overflow: hidden;
  .spot {
    position: absolute;
    top: -45%;
    left: 50%;
    opacity: 0.5;
  }
  .grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2rem;
    padding: 0px 15px;

    .card {
      position: relative;

      .gridChart {
        display: grid;
        grid-template-columns: 0.6fr 0.6fr;
        grid-gap: 1rem;
        margin-bottom: 2rem;
      }
      h3 {
        font-size: 45px;
        font-weight: 600;
        width: 90%;
        line-height: 58px;
        color: #0e0a01;
      }
      p {
        margin-top: 1.5rem;
        line-height: 27px;
        width: 100%;
        margin-bottom: 1.8rem;
        font-size: 18px;
        text-align: justify;
      }
      .imgMain {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        /* border-top: 12px solid #1378be; */
        .aboutImg {
          width: 80%;
          height: 400px;
        }
        @media only screen and (max-width: 991px) {
          .aboutImg {
            width: 90%;
            height: 400px;
          }
        }
      }
      .border {
        position: absolute;
        top: 0%;
        background-color: #1378be;
        width: 90%;
        height: 12px;
      }
      .data {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #0e1e36;
        padding: 10px 20px;
        width: 90%;
        color: #fff;

        h2 {
          font-size: 35px;
          font-weight: 500;
          color: #fff;
          margin-bottom: 0rem !important;
        }
        h3 {
          font-size: 25px;
          font-weight: 500;
          color: #fff;
          text-align: center;
        }
        p {
          font-size: 16px;
          color: #fff;
          margin-top: 0rem !important;
          margin-bottom: 0.8rem !important;
        }
        @media only screen and (max-width: 991px) {
          padding: 5px 20px;
          h2 {
            font-size: 30px;
            margin-bottom: -1rem !important;
          }
          h3 {
            font-size: 22px;
            margin-bottom: -0.7rem !important;
          }
          p {
            font-size: 14px;
          }
        }
      }
    }

    @media only screen and (max-width: 991px) {
      grid-template-columns: 1fr;
    }
  }
`;
